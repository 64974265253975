import { useContext } from 'react'
import { gql, useQuery } from '@apollo/client'
import { BookingContext } from './App'

const GET_FEES_AND_DISCOUNTS = gql`
    {
        serviceFee: fees(name: "Service Fee") {
            name
            amount
            feeType
            isAutoApply
        }
        pwdDiscount: discounts(name: "PWD Discount") {
            name
            amount
            discountType
        }
        governmentDiscount: discounts(name: "Government Agency Discount") {
            name
            amount
            discountType
        }
    }
`

const useTotalCost = ({
    selectedUnits,
    dayDiff,
    isPwd,
    pwds = 0,
    isGovernment,
    numberOfHeads,
}) => {
    const { loading, data } = useQuery(GET_FEES_AND_DISCOUNTS, {
        fetchPolicy: 'network-only',
    })
    const { selectedPackage, packageType } = useContext(BookingContext)
    const serviceFee = data ? data.serviceFee[0] : null
    const pwdDiscount = data ? data.pwdDiscount[0] : null
    const governmentDiscount = data ? data.governmentDiscount[0] : null
    const subTotal =
        selectedPackage && packageType
            ? packageType.price * numberOfHeads
            : selectedUnits.reduce(
                  (acc, curr) => acc + curr.capacityPrice * dayDiff,
                  0
              )
    const sortedSelectedUnits = [...selectedUnits].sort(
        (a, b) => b.capacityPrice - a.capacityPrice
    )
    const pwdDiscountTotal =
        pwdDiscount && !isGovernment && (isPwd || pwds > 0)
            ? pwdDiscount.discountType === 'percent'
                ? sortedSelectedUnits.reduce(
                      (acc, curr) =>
                          acc.remaining > 0
                              ? isPwd && acc.total === 0
                                  ? {
                                        remaining: acc.remaining - 1,
                                        total:
                                            ((curr.capacityPrice * dayDiff) /
                                                100) *
                                            pwdDiscount.amount,
                                    }
                                  : {
                                        remaining:
                                            acc.remaining - curr.capacity,
                                        total:
                                            acc.total +
                                            ((curr.capacityPrice * dayDiff) /
                                                curr.capacity /
                                                100) *
                                                pwdDiscount.amount *
                                                (acc.remaining >= curr.capacity
                                                    ? curr.capacity
                                                    : acc.remaining),
                                    }
                              : acc,
                      { remaining: pwds, total: 0 }
                  ).total
                : pwdDiscount.amount * pwds
            : 0
    const governmentDiscountTotal =
        governmentDiscount && isGovernment
            ? governmentDiscount.discountType === 'percent'
                ? (subTotal / 100) * governmentDiscount.amount
                : governmentDiscount.amount
            : 0
    const serviceFeeTotal = serviceFee
        ? serviceFee.isAutoApply && serviceFee.feeType === 'percent'
            ? ((subTotal - pwdDiscountTotal - governmentDiscountTotal) / 100) *
              serviceFee.amount
            : serviceFee.amount
        : 0
    const totalCost =
        subTotal - pwdDiscountTotal - governmentDiscountTotal + serviceFeeTotal
    return {
        loading,
        serviceFee,
        serviceFeeTotal,
        pwdDiscount,
        pwdDiscountTotal,
        governmentDiscount,
        governmentDiscountTotal,
        subTotal,
        totalCost,
    }
}

export default useTotalCost
