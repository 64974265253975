import React, { Fragment, useContext, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { gql, useMutation } from '@apollo/client'
import { BookingContext } from './App'
import GuestDetailsForm from './GuestDetailsForm'
import BookingSummary from './BookingSummary'
import BookingSuccess from './BookingSuccess'
import { isValidEmail } from './utils'
import { isPossiblePhoneNumber } from 'react-phone-number-input'

const CREATE_BOOKING = gql`
    mutation CreateBooking(
        $booking: BookingAttributes!
        $reservations: [ReservationAttributes!]!
    ) {
        createBooking(booking: $booking, reservations: $reservations) {
            success
            errors
            referenceNumber
        }
    }
`

const BookingDetails = (props) => {
    const {
        selectedPackage,
        packageType,
        adults,
        children,
        checkIn,
        checkOut,
    } = useContext(BookingContext)
    const [createBooking, { loading, data }] = useMutation(CREATE_BOOKING)
    const [category, setCategory] = useState(null)
    const [guestType, setGuestType] = useState(null)
    const [guestName, setGuestName] = useState('')
    const [contactPerson, setContactPerson] = useState('')
    const [contactNumber, setContactNumber] = useState('')
    const [email, setEmail] = useState('')
    const [address, setAddress] = useState('')
    const [activityPurpose, setActivityPurpose] = useState('')
    const [isPwd, setIsPwd] = useState(null)
    const [pwds, setPwds] = useState(0)
    const [isValidated, setIsValidated] = useState(false)
    const valid =
        category &&
        guestType &&
        guestName &&
        contactPerson &&
        contactNumber &&
        isPossiblePhoneNumber(contactNumber) &&
        isValidEmail(email) &&
        activityPurpose &&
        pwds <= adults

    const selectedGuestHouses =
        (props.location && props.location.selectedGuestHouses) || []
    const selectedDormitoryRooms =
        (props.location && props.location.selectedDormitoryRooms) || []
    const selectedConferenceHalls =
        (props.location && props.location.selectedConferenceHalls) || []
    const selectedGrounds =
        (props.location && props.location.selectedGrounds) || []
    let reservations = []
    if (selectedGuestHouses.length > 0) {
        reservations = reservations.concat({
            unitType: 'Guest House',
            ids: selectedGuestHouses.reduce(
                (acc, curr) => acc.concat(curr.id),
                []
            ),
        })
    } else if (selectedDormitoryRooms.length > 0) {
        reservations = reservations.concat({
            unitType: 'Dormitory Room',
            ids: selectedDormitoryRooms.reduce(
                (acc, curr) => acc.concat(curr.id),
                []
            ),
        })
    } else if (selectedConferenceHalls.length > 0) {
        reservations = reservations.concat({
            unitType: 'Conference Hall',
            ids: selectedConferenceHalls.reduce(
                (acc, curr) => acc.concat(curr.id),
                []
            ),
        })
    } else if (selectedGrounds.length > 0) {
        reservations = reservations.concat({
            unitType: 'Ground',
            rateIds: selectedGrounds.reduce(
                (acc, curr) => acc.concat(curr.rateId),
                []
            ),
        })
    }

    const handleSubmit = () => {
        if (!valid) {
            setIsValidated(true)
            window.scrollTo(0, 0)
        } else {
            createBooking({
                variables: {
                    booking: {
                        guestType,
                        guestName,
                        contactPerson,
                        contactNumber,
                        email,
                        checkIn,
                        checkOut,
                        category,
                        address,
                        activityPurpose,
                        isSeniorGuest: isPwd || false,
                        packageId: selectedPackage ? selectedPackage.id : null,
                        rateId: packageType ? packageType.id : null,
                        checkIn,
                        checkOut,
                        adultGuests: parseInt(adults),
                        seniorGuests: parseInt(pwds),
                        childGuests: children,
                    },
                    reservations,
                },
            })
        }
    }

    return (
        <Fragment>
            {reservations.length > 0 ? (
                data && data.createBooking.success ? (
                    <BookingSuccess
                        email={email}
                        referenceNumber={data.createBooking.referenceNumber}
                    />
                ) : (
                    <div className="pt-5">
                        <button
                            className="btn btn-link d-flex align-items-center pl-0 mb-3"
                            onClick={() => props.history.goBack()}
                        >
                            <span className="fe fe-arrow-left mr-2"></span>
                            Go back to unit selection
                        </button>
                        <h3>Guest Details</h3>
                        <div className="mb-5">
                            <GuestDetailsForm
                                guestType={guestType}
                                setGuestType={setGuestType}
                                category={category}
                                setCategory={setCategory}
                                guestName={guestName}
                                setGuestName={setGuestName}
                                contactPerson={contactPerson}
                                setContactPerson={setContactPerson}
                                contactNumber={contactNumber}
                                setContactNumber={setContactNumber}
                                email={email}
                                setEmail={setEmail}
                                address={address}
                                setAddress={setAddress}
                                activityPurpose={activityPurpose}
                                setActivityPurpose={setActivityPurpose}
                                isPwd={isPwd}
                                setIsPwd={setIsPwd}
                                pwds={pwds}
                                setPwds={setPwds}
                                isValidated={isValidated}
                                setIsValidated={setIsValidated}
                                loading={loading}
                            />
                        </div>
                        <h3>Reservation</h3>
                        <BookingSummary
                            selectedGuestHouses={selectedGuestHouses}
                            selectedDormitoryRooms={selectedDormitoryRooms}
                            selectedConferenceHalls={selectedConferenceHalls}
                            selectedGrounds={selectedGrounds}
                            bookingLoading={loading}
                            handleSubmit={handleSubmit}
                            isGovernment={category === 'Government'}
                            isPwd={isPwd}
                            pwds={pwds}
                        />
                    </div>
                )
            ) : (
                <Redirect to="/home" />
            )}
        </Fragment>
    )
}

export default BookingDetails
