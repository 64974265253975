import React, { Fragment, useContext } from 'react'
import { BookingContext } from './App'
import NumberCounter from './NumberCounter'
import { currencyFormat } from './utils'

const ConferenceHallUnitCounter = ({
    availableUnits,
    selectedUnits,
    setSelectedUnits,
}) => {
    const { adults } = useContext(BookingContext)

    const units = availableUnits
        ? availableUnits.filter((u) => u.capacity >= adults)
        : []

    const handleAddUnit = (unit) => {
        setSelectedUnits(
            selectedUnits.concat(unit).sort((a, b) => a.capacity - b.capacity)
        )
    }

    const handleSubtractUnit = (unit) => {
        setSelectedUnits(selectedUnits.filter((u) => u.id !== unit.id))
    }

    const unitRows = units.map((unit, index) => {
        const selected = selectedUnits.some((u) => u.id === unit.id)
        return (
            <div
                key={unit.id}
                className="booking-details-card d-flex align-items-center py-3 px-4 mb-3"
                style={selected ? { borderColor: 'var(--primary' } : {}}
            >
                <div className="d-flex flex-column mr-auto">
                    <span className="font-weight-bolder">{unit.name}</span>
                    <span>
                        {unit.capacity} capacity · ₱
                        {currencyFormat(unit.capacityPrice)}/day
                    </span>
                </div>
                <NumberCounter
                    number={selected ? 1 : 0}
                    handleAdd={() => handleAddUnit(unit)}
                    handleSubtract={() => handleSubtractUnit(unit)}
                    isAddDisabled={selected || selectedUnits.length >= adults}
                />
            </div>
        )
    })

    return <Fragment>{unitRows}</Fragment>
}

export default ConferenceHallUnitCounter
