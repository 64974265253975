import React, { useContext, useState } from 'react'
import { BookingContext } from './App'
import Dropdown from './Dropdown'

const allOptions = [
    { label: 'Guest House', value: 'Guest House' },
    { label: 'Dormitory Room', value: 'Dormitory Room' },
    { label: 'Conference Hall', value: 'Conference Hall' },
    { label: 'Ground', value: 'Ground' },
]

const lodgingOptions = [
    { label: 'Guest House', value: 'Guest House' },
    { label: 'Dormitory Room', value: 'Dormitory Room' },
]

const UnitTypeDropdown = ({
    compact,
    handleChange = () => {},
    isLodgingOnly,
}) => {
    const { unitType, setUnitType, adults, setAdults, children, setChildren } =
        useContext(BookingContext)
    const [childrenThen, setChildrenThen] = useState(0)
    const isLodgingUnitType =
        unitType &&
        (unitType === 'Guest House' || unitType === 'Dormitory Room')

    const handleOnChange = (type) => {
        if (
            !isLodgingUnitType &&
            (type === 'Guest House' || type === 'Dormitory Room')
        ) {
            setAdults(adults - childrenThen)
            setChildren(childrenThen)
            setChildrenThen(0)
        } else if (
            (!unitType || isLodgingUnitType) &&
            (type === 'Conference Hall' || type === 'Ground')
        ) {
            setAdults(adults + children)
            setChildrenThen(children)
            setChildren(0)
        }
        handleChange()
        setUnitType(type)
    }

    return (
        <div
            style={
                compact
                    ? {
                          borderBottom: '1px solid var(--light-gray)',
                          padding: '0 1rem 0.65rem',
                          marginBottom: '0.75rem',
                      }
                    : {
                          marginRight: 'auto',
                      }
            }
        >
            <h3 className="mb-2 ml-2">Type</h3>
            <Dropdown
                value={unitType}
                options={isLodgingOnly ? lodgingOptions : allOptions}
                handleOptionSelect={handleOnChange}
                placeholder={isLodgingOnly ? 'Lodging Type' : 'Select Type'}
            />
        </div>
    )
}

export default UnitTypeDropdown
