import React, { Fragment } from 'react'
import aboutImg1 from '../../assets/images/btc-008.jpg'
import aboutImg2 from '../../assets/images/btc-0013.jpg'
import aboutImg3 from '../../assets/images/btc-0015.jpg'

const About = () => (
    <Fragment>
        <div className="header-overline-primary mt-6"></div>
        <div className="d-flex flex-wrap mb-4">
            <div className="col-12 col-lg-6 p-0 pb-3 pr-lg-6">
                <h1>About Us</h1>
                <h3 className="mb-2">History</h3>
                <p>
                    Established in 1908, the Baguio Teachers Camp (BTC) served
                    as a training center and vacation site for Filipino and
                    American teachers where education-related activities were
                    held yearly during the summer season. It has been a living
                    witness to major assemblies and events that harnessed the
                    evolution of Philippine Education. At its centennial
                    celebration on May 12, 2008, the BTC was declared a national
                    heritage site that must be protected and preserved. Thus,
                    the Department of Education is determined and exerts all-out
                    effort to maintain BTC’s historical and cultural legacy in
                    its entirety.
                </p>
                <h3 className="mb-2">Improvements</h3>
                <p>
                    While still preserving the atmosphere of the old Baguio, BTC
                    started improving its main facilities towards the end of
                    2018. Parts of the flooring of the Administration Building
                    were replaced. New lighting fixtures and updated security
                    system were installed, and its interior was completely
                    repainted – same with the dormitories and cottages where
                    interiors were improved, showcasing intricate Cordillera
                    patterns and designs. The conference and mess halls namely
                    Albert, Romulo, Quirino, and Roxas, also went through major
                    rehabilitation. There are visible changes in their building
                    components, as floors, windows, doors, ceiling, and roofing
                    were repaired. These halls can accommodate hundreds of
                    guests at one time, making them the perfect venue for
                    activities and events of public and private groups.
                </p>
                <h3 className="mb-2">Security</h3>
                <p>
                    For the convenience and security of guests and visitors, the
                    Camp is now equipped with CCTV cameras and monitors, light
                    and sound systems, LED screens, and projectors. BTC also
                    continues to improve its surroundings through landscaping
                    and the regular planting of new pine trees.
                </p>
            </div>
            <div className="col-12 col-lg-6 px-0">
                <img
                    src={aboutImg1}
                    className="col-12 px-0 mb-3 object-fit-cover"
                />
                <div className="d-flex col-6 col-lg-12 px-0">
                    <img
                        src={aboutImg2}
                        className="col-12 col-lg-6 pl-0 pr-2 object-fit-cover"
                    />
                    <img
                        src={aboutImg3}
                        className="col-12 col-lg-6 pl-2 pr-0 object-fit-cover"
                    />
                </div>
            </div>
        </div>
    </Fragment>
)

export default About
