import { useContext } from 'react'
import { gql, useQuery } from '@apollo/client'
import { BookingContext } from './App'

const GET_AVAILABLE_UNITS = gql`
    query GetAvailableUnits(
        $type: String!
        $checkIn: String!
        $checkOut: String!
    ) {
        getAvailableUnits(type: $type, checkIn: $checkIn, checkOut: $checkOut) {
            id
            unitType
            name
            fullName
            capacity
            numberOfRooms
            numberOfBathrooms
            capacityPrice
        }
    }
`

const useAvailableUnits = () => {
    const { unitType, checkIn, checkOut } = useContext(BookingContext)
    const { loading, data } = useQuery(GET_AVAILABLE_UNITS, {
        variables: {
            type: unitType,
            checkIn,
            checkOut,
        },
        fetchPolicy: 'network-only',
    })

    return {
        loading,
        data,
    }
}

export default useAvailableUnits
