import React, { Fragment, useContext } from 'react'
import { Link } from 'react-router-dom'
import { BookingContext } from './App'
import { currencyFormat } from './utils'
import useTotalCost from './useTotalCost'
import moment from 'moment'

const ReservationSummary = ({
    selectedGuestHouses = [],
    selectedDormitoryRooms = [],
    selectedConferenceHalls = [],
    selectedGrounds = [],
}) => {
    const {
        selectedPackage,
        packageType,
        adults,
        children,
        checkIn,
        checkOut,
    } = useContext(BookingContext)
    const selectedUnits = selectedGuestHouses.concat(
        selectedDormitoryRooms,
        selectedConferenceHalls,
        selectedGrounds
    )
    const bedsOccupied =
        selectedGuestHouses.reduce((acc, curr) => acc + curr.capacity, 0) +
        selectedDormitoryRooms.reduce((acc, curr) => acc + curr.capacity, 0)
    const conferenceHallOccupation = selectedConferenceHalls.reduce(
        (acc, curr) => acc + curr.capacity,
        0
    )
    const groundsOccupation = selectedGrounds.reduce(
        (acc, curr) => acc + curr.capacity,
        0
    )
    const numberOfHeads = [
        bedsOccupied,
        conferenceHallOccupation,
        groundsOccupation,
    ].sort((a, b) => b - a)[0]
    const dayDiff =
        checkOut.diff(checkIn, 'days') +
        (selectedGuestHouses.length > 0 || selectedDormitoryRooms.length > 0
            ? 0
            : 1)
    const { loading, serviceFee, serviceFeeTotal, subTotal, totalCost } =
        useTotalCost({
            selectedUnits,
            dayDiff,
            numberOfHeads,
        })
    const unitRows = selectedUnits.map((u) => (
        <div key={u.id} className="d-flex align-items-center py-3">
            <div className="d-flex flex-column mr-auto">
                {u.unitType === 'Ground' ? (
                    <span className="font-weight-bolder">{`${u.name} (${u.description})`}</span>
                ) : (
                    <span className="font-weight-bolder">{u.name}</span>
                )}
                <span>
                    ₱{currencyFormat(u.capacityPrice)}
                    {u.unitType === 'Conference Hall' || u.unitType === 'Ground'
                        ? '/day'
                        : '/night'}
                </span>
            </div>
            {!selectedPackage && (
                <h4 className="mb-0 ml-3">
                    ₱{currencyFormat(u.capacityPrice * dayDiff)}
                </h4>
            )}
        </div>
    ))

    return (
        <div className="lighter-blue-card sticky-box">
            {selectedUnits.length > 0 ? (
                <Fragment>
                    <h4 className="mb-5">
                        Here's the summary of your booking:
                    </h4>
                    <h4 className="mb-0">Booking Schedule:</h4>
                    <p>
                        {moment(checkIn).format('ddd, MMMM DD, YYYY')}
                        {dayDiff > 1 ||
                        selectedGuestHouses.length > 0 ||
                        selectedDormitoryRooms.length > 0
                            ? ` - ${moment(checkOut).format(
                                  'ddd, MMMM DD, YYYY'
                              )}`
                            : ''}
                    </p>
                    <h4 className="mb-0">Number of Guests:</h4>
                    <p className="mb-5">
                        {adults > 0
                            ? `${adults} adult${adults > 1 ? 's' : ''}`.concat(
                                  children > 0
                                      ? `, ${children} child${
                                            children > 1 ? 'ren' : ''
                                        }`
                                      : ''
                              )
                            : children > 0
                            ? `${children} child${children > 1 ? 'ren' : ''}`
                            : ''}
                    </p>
                    <h4>Units for reservation:</h4>
                    <div className="light-blue-card p-4 mb-5">
                        <div
                            className="py-3 mb-4"
                            style={{
                                borderBottom: '1px solid var(--light-gray)',
                            }}
                        >
                            {selectedPackage && packageType && (
                                <Fragment>
                                    <div className="d-flex align-items-center">
                                        <div className="mr-auto">
                                            <h4 className="mb-0">
                                                {selectedPackage.name}
                                            </h4>
                                            <p className="font-size-sm mb-0">
                                                ₱
                                                {currencyFormat(
                                                    packageType.price
                                                )}
                                                /head
                                            </p>
                                        </div>
                                        <h4 className="mb-0">
                                            x {numberOfHeads}
                                        </h4>
                                    </div>
                                </Fragment>
                            )}
                            {unitRows}
                        </div>
                        {loading ? (
                            <div className="d-flex justify-content-center w-100">
                                <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        ) : (
                            <Fragment>
                                {serviceFeeTotal > 0 && (
                                    <div
                                        className="pb-4 mb-4"
                                        style={{
                                            borderBottom:
                                                '1px solid var(--light-gray)',
                                        }}
                                    >
                                        <div className="d-flex align-items-center mb-2">
                                            <div className="mr-auto">
                                                Sub Total
                                            </div>
                                            <div>
                                                ₱{currencyFormat(subTotal)}
                                            </div>
                                        </div>
                                        {serviceFeeTotal > 0 && (
                                            <div className="d-flex align-items-center">
                                                <div className="d-flex align-items-center mr-auto">
                                                    <span className="mr-1">
                                                        Service Fee
                                                        {serviceFee &&
                                                        serviceFee.feeType ===
                                                            'percent'
                                                            ? ` (${serviceFee.amount}%)`
                                                            : ''}
                                                    </span>
                                                    <a
                                                        href="/help#fees"
                                                        target="_blank"
                                                    >
                                                        <span className="fe fe-help-circle"></span>
                                                    </a>
                                                </div>
                                                <div>
                                                    ₱
                                                    {currencyFormat(
                                                        serviceFeeTotal
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}
                                <div
                                    className="d-flex align-items-center"
                                    style={{ color: 'var(--blue)' }}
                                >
                                    <h3 className="mr-auto">TOTAL</h3>
                                    <h3>₱{currencyFormat(totalCost)}</h3>
                                </div>
                            </Fragment>
                        )}
                    </div>
                    <div className="d-flex justify-content-center mb-3">
                        {numberOfHeads >= adults &&
                        (selectedPackage ? packageType !== null : true) ? (
                            <Link
                                className="btn btn-primary"
                                style={{ width: '40%' }}
                                to={{
                                    pathname: '/bookingDetails',
                                    selectedGuestHouses,
                                    selectedDormitoryRooms,
                                    selectedConferenceHalls,
                                    selectedGrounds,
                                }}
                            >
                                Reserve
                            </Link>
                        ) : (
                            <button
                                className="btn btn-primary disabled px-4"
                                style={{
                                    width: '40%',
                                }}
                                disabled
                            >
                                Reserve
                            </button>
                        )}
                    </div>
                    <p className="text-center">You won't be charged yet</p>
                </Fragment>
            ) : (
                <div
                    className="d-flex align-items-center justify-content-center"
                    style={{ height: '200px' }}
                >
                    <h3>Select preferred available units to view summary.</h3>
                </div>
            )}
        </div>
    )
}

export default ReservationSummary
