export const positiveNumber = (e) => {
    // e, +, -
    if ([69, 187, 189].includes(e.keyCode)) {
        e.preventDefault()
    }
}

export const currencyFormat = (curr) => {
    return Math.ceil(curr).toLocaleString()
}

export const isValidEmail = (email) => {
    const re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(email)
}
