import React, { useContext } from 'react'
import { BookingContext } from './App'
import Dropdown from './Dropdown'

const PackageTypeDropdown = () => {
    const { selectedPackage, packageType, setPackageType } =
        useContext(BookingContext)
    const packageTypes = selectedPackage.rates
    const packageTypeOptions = packageTypes.map((r) => {
        return { label: r.name, value: r.id }
    })

    return (
        <div className="p-4">
            <div className="form-control py-2 mb-4">
                <Dropdown
                    value={packageType ? packageType.name : null}
                    options={packageTypeOptions}
                    handleOptionSelect={(packageType) =>
                        setPackageType(
                            packageTypes.find((r) => packageType === r.id)
                        )
                    }
                    placeholder="Choose one"
                />
            </div>
            {packageType && <p className="mb-0">{packageType.description}</p>}
        </div>
    )
}

export default PackageTypeDropdown
