import React, { useContext, useState } from 'react'
import { BookingContext } from './App'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import { HashLink, NavHashLink } from 'react-router-hash-link'
import icon from '../../assets/images/btc-icon-white.png'

const MainNavigation = () => {
    const { resetState } = useContext(BookingContext)
    const [expanded, setExpanded] = useState(false)

    const handleSelectWithResetState = () => {
        resetState()
        setExpanded(false)
    }

    return (
        <Navbar
            expand="md"
            expanded={expanded}
            className="navbar-dark position-sticky"
            style={{
                border: 'none',
                backgroundColor: 'var(--primary)',
                paddingTop: '0.5rem',
                paddingBottom: '0.5rem',
                zIndex: '3',
                top: '0',
            }}
        >
            <Navbar.Brand className="logo-margin-left mr-md-4">
                <HashLink smooth to="/home#" className="nav-link">
                    <img
                        src={icon}
                        className="mw-100 mr-1"
                        style={{ maxHeight: '28px' }}
                    />
                </HashLink>
            </Navbar.Brand>
            <Navbar.Toggle
                aria-controls="basic-navbar-nav"
                children={
                    <span
                        className="fe fe-menu"
                        style={{ color: '#FFFFFF', fontSize: '24px' }}
                    ></span>
                }
                onClick={() => setExpanded(!expanded)}
            />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav>
                    <NavHashLink
                        smooth
                        to="/home#"
                        className="nav-link"
                        activeClassName="active"
                        isActive={(match, location) =>
                            location.pathname === '/' ||
                            location.pathname === '/home'
                        }
                        onClick={() => setExpanded(false)}
                    >
                        Home
                    </NavHashLink>
                    <NavHashLink
                        smooth
                        to="/about#"
                        className="nav-link"
                        activeClassName="active"
                        isActive={(match, location) =>
                            location.pathname === '/about'
                        }
                        onClick={() => setExpanded(false)}
                    >
                        About Us
                    </NavHashLink>
                    <NavHashLink
                        smooth
                        to="/lodgingBooking#"
                        className="nav-link"
                        activeClassName="active"
                        isActive={(match, location) =>
                            location.pathname === '/lodgingBooking'
                        }
                        onClick={() => handleSelectWithResetState()}
                    >
                        Lodgings
                    </NavHashLink>
                    <NavHashLink
                        smooth
                        to="/conferenceHallBooking#"
                        className="nav-link"
                        activeClassName="active"
                        isActive={(match, location) =>
                            location.pathname === '/conferenceHallBooking'
                        }
                        onClick={() => handleSelectWithResetState()}
                    >
                        Conference Halls
                    </NavHashLink>
                    <NavHashLink
                        smooth
                        to="/groundBooking#"
                        className="nav-link"
                        activeClassName="active"
                        isActive={(match, location) =>
                            location.pathname === '/groundBooking'
                        }
                        onClick={() => handleSelectWithResetState()}
                    >
                        Grounds
                    </NavHashLink>
                    {/* <NavHashLink
                            smooth
                            to="/packageBooking#"
                            className="nav-link"
                            activeClassName="active"
                            isActive={(match, location) =>
                                location.pathname === '/packageBooking'
                            }
                            onClick={() => resetState()}
                        >
                            Packages
                        </NavHashLink> */}
                    <NavHashLink
                        smooth
                        to="/help#"
                        className="nav-link"
                        activeClassName="active"
                        isActive={(match, location) =>
                            location.pathname === '/help'
                        }
                        onClick={() => setExpanded(false)}
                    >
                        Help Center
                    </NavHashLink>
                    <NavHashLink
                        smooth
                        to="/contact#"
                        className="nav-link"
                        activeClassName="active"
                        isActive={(match, location) =>
                            location.pathname === '/contact'
                        }
                        onClick={() => setExpanded(false)}
                    >
                        Contact Us
                    </NavHashLink>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default MainNavigation
