import React from 'react'

const RefundsAndCancellations = () => (
    <div id="refunds" className="help-center-section">
        <h2>Refunds and Cancellations</h2>
        <ul>
            <li>
                The client shall notify cancellations to Baguio Teachers Camp
                management not later than fifteen (15) days prior to check-in
                for Organizations, or five (5) days prior for Individual guests.
                Failure to inform forfeits eligibility to refund the 10% minimum
                payment collected as Confirmation Fee. Guests may be eligible
                for other refunds subject to processing of cancellation request
                with Baguio Teachers Camp management.
            </li>
            <li>
                Rescheduling of functions/activity is allowed subject to the
                availability of facilities.
            </li>
            <li>
                Fully paid guests cancelling a reservation within two (2) hours
                after registration shall get a full refund of the amount less
                the 10% Confirmation Fee.
            </li>
            <li>
                In cases of cancellation due to fortuitous events, force
                majeure, or acts of God as defined under law, both parties will
                make an agreement for rescheduling or refund.
            </li>
        </ul>
    </div>
)

export default RefundsAndCancellations
