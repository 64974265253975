import React from 'react'

const Confirmation = () => (
    <div id="confirmation" className="help-center-section">
        <h2>Confirmation</h2>
        <ul>
            <li>
                Registration of a booking through the Baguio Teachers Camp
                system and confirmation of such booking serves as an agreement
                between the guest and Baguio Teachers Camp management on the
                minimum number of guests to be served under such booking. No
                refunds will be issued if the number of actual checked-in guests
                is less than the minimum number of guests indicated. If actual
                checked-in guests exceed the minimum number, guests will be
                billed for actual attendance.
            </li>
            <li>
                A minimum of ten percent (10%) of the total reservation cost
                must be paid as a Confirmation Fee at least seven (7) days
                before check-in for Individual guests, or one (1) month before
                check-in for Non-government Organizations. This minimum is not
                required for Government Organizations.
            </li>
            <li>
                Bookings must be confirmed paid in full by Baguio Teachers Camp
                management on or before the last day of the booking, prior to
                check-out.
            </li>
        </ul>
    </div>
)

export default Confirmation
