import React, { Fragment, useContext } from 'react'
import Form from 'react-bootstrap/Form'
import { BookingContext } from './App'
import { currencyFormat } from './utils'

const GroundUnitSelector = ({
    availableUnits,
    selectedUnits,
    setSelectedUnits,
}) => {
    const { adults } = useContext(BookingContext)

    const units = availableUnits
        ? availableUnits.filter((u) => u.unit.capacity >= adults)
        : []

    const handleAddUnit = ({ unit, rate }) => {
        const isSelected = selectedUnits.find((u) => u.id === unit.unit.id)
        const selectedUnit = isSelected || {
            ...unit.unit,
            capacityPrice: rate.price,
            description: rate.description,
            rateId: rate.id,
        }
        if (isSelected) {
            setSelectedUnits(
                selectedUnits.map((u) =>
                    u.id === selectedUnit.id
                        ? {
                              ...selectedUnit,
                              capacityPrice: rate.price,
                              description: rate.description,
                              rateId: rate.id,
                          }
                        : u
                )
            )
        } else {
            setSelectedUnits(
                selectedUnits
                    .concat(selectedUnit)
                    .sort((a, b) => a.capacity - b.capacity)
            )
        }
    }

    const handleRemoveUnit = (unit) => {
        setSelectedUnits(selectedUnits.filter((u) => u.id !== unit.unit.id))
    }

    const unitRows = units.map((unit) => {
        const selected = selectedUnits.find((u) => u.id === unit.unit.id)
        const wholeDay = unit.rates.find((r) => r.name === 'Whole Day')
        const halfDays = unit.rates.filter((r) => r.name !== 'Whole Day')
        return (
            <div
                key={unit.unit.id}
                className="booking-details-card p-4 mb-3"
                style={selected ? { borderColor: 'var(--primary' } : {}}
            >
                <p className="font-weight-bolder mb-0">{unit.unit.name}</p>
                <p>
                    {unit.unit.capacity} capacity ·
                    {wholeDay && (
                        <span> ₱{currencyFormat(wholeDay.price)}/day and</span>
                    )}
                    {halfDays.length > 1 && (
                        <span>
                            {' '}
                            ₱{currencyFormat(halfDays[0].price)}
                            /half-day
                        </span>
                    )}
                </p>
                <div
                    className="pt-4 mb-4"
                    style={{
                        borderTop: '1px solid var(--light-gray)',
                    }}
                >
                    {wholeDay && (
                        <Form.Check
                            type={'radio'}
                            label={`${wholeDay.name} (${wholeDay.description})`}
                            name={unit.unit.id}
                            className="custom-checkbox mb-3"
                            disabled={
                                !selected && selectedUnits.length >= adults
                            }
                            onChange={() =>
                                handleAddUnit({ unit, rate: wholeDay })
                            }
                            checked={
                                selected
                                    ? selected.rateId === wholeDay.id
                                    : false
                            }
                        />
                    )}
                    {halfDays.length > 0 &&
                        halfDays.map((r) => (
                            <Form.Check
                                key={r.id}
                                type={'radio'}
                                name={unit.unit.id}
                                label={`${r.name} (${r.description})`}
                                className="custom-checkbox"
                                disabled={
                                    !selected && selectedUnits.length >= adults
                                }
                                onChange={() =>
                                    handleAddUnit({ unit, rate: r })
                                }
                                checked={
                                    selected ? selected.rateId === r.id : false
                                }
                            />
                        ))}
                </div>
                {selected && (
                    <button
                        className="btn btn-outline-primary outline-none"
                        onClick={() => handleRemoveUnit(unit)}
                    >
                        Clear selection
                    </button>
                )}
            </div>
        )
    })

    return <Fragment>{unitRows}</Fragment>
}

export default GroundUnitSelector
