import React, { Fragment, useContext, useState, useEffect } from 'react'
import { BookingContext } from './App'
import UnitsLightbox from './UnitsLightbox'
import BookingSearch from './BookingSearch'
import GroundUnitSelector from './GroundUnitSelector'
import ReservationSummary from './ReservationSummary'
import useAvailableRates from './useAvailableRates'

const GroundBooking = () => {
    const { unitType, setUnitType, adults, checkIn, checkOut } =
        useContext(BookingContext)
    const searchComplete = checkIn && checkOut
    const valid =
        searchComplete && !checkIn.isSame(new Date(), 'day') && adults > 0
    const { loading, data } = useAvailableRates()
    const [selectedUnits, setSelectedUnits] = useState([])
    const unitsLength = data && data.getAvailableRates.length

    useEffect(() => {
        setUnitType('Ground')
    }, [])

    return (
        <Fragment>
            <div className="mt-4 mb-5">
                <UnitsLightbox unitType={unitType} />
            </div>
            <div className="d-flex flex-wrap">
                <div className="col-12 col-xl-6 px-0">
                    <div className="border-bottom-container">
                        <h1>Grounds</h1>
                    </div>
                    <div className="border-bottom-container">
                        <h3>About our grounds</h3>
                        <p style={{ color: 'var(--gray)' }}>
                            BTC is located in a beautiful, spacious, campus-like
                            environment close to the city center, with its main
                            entrance along Leonard Wood Road with another
                            entrance on posh South Drive. For the past 100
                            years, BTC served as a training center and venue for
                            teachers from all over the Philippines who come
                            during the summer break to attend special courses in
                            education.
                        </p>
                    </div>
                    <h3 id="search">Add details</h3>
                    <p className="mb-4" style={{ color: 'var(--gray)' }}>
                        Please fill up search fields.
                    </p>
                    <div className="booking-details-card pt-3 mb-5">
                        <BookingSearch
                            dayOffset={1}
                            minimumNights={0}
                            handleChange={() => setSelectedUnits([])}
                            compact
                            withManyGuests
                            guestsClassName="pb-3"
                        />
                    </div>
                    <div className="mb-5">
                        <h3>Available Grounds</h3>
                        {!loading &&
                        valid &&
                        unitsLength > 0 &&
                        data.getAvailableRates[unitsLength - 1].unit.capacity >=
                            adults ? (
                            <Fragment>
                                <p
                                    className="mb-4"
                                    style={{ color: 'var(--gray)' }}
                                >
                                    Please select grounds you want to reserve.
                                </p>
                                <GroundUnitSelector
                                    availableUnits={
                                        data ? data.getAvailableRates : []
                                    }
                                    selectedUnits={selectedUnits}
                                    setSelectedUnits={setSelectedUnits}
                                />
                            </Fragment>
                        ) : (
                            <div
                                className="booking-details-card d-flex align-items-center justify-content-center p-5"
                                style={{ height: '300px', color: 'var(--gray' }}
                            >
                                {loading ? (
                                    <div
                                        className="spinner-border"
                                        role="status"
                                    >
                                        <span className="sr-only">
                                            Loading...
                                        </span>
                                    </div>
                                ) : valid ? (
                                    <h3 className="mb-0">
                                        Sorry there are no available grounds
                                        with the given search fields.
                                    </h3>
                                ) : (
                                    <div>
                                        {!searchComplete && (
                                            <h3 className="mb-2">
                                                Please fill up search fields.
                                            </h3>
                                        )}
                                        {checkIn &&
                                            checkIn.isSame(
                                                new Date(),
                                                'day'
                                            ) && (
                                                <h3 className="mb-2">
                                                    Check in cannot be today.
                                                </h3>
                                            )}
                                        {adults <= 0 && (
                                            <h3 className="mb-0">
                                                There should be at least one
                                                guest.
                                            </h3>
                                        )}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
                {valid && (
                    <div className="col-12 col-xl-6 p-0 pl-xl-5">
                        <ReservationSummary selectedGrounds={selectedUnits} />
                    </div>
                )}
            </div>
        </Fragment>
    )
}

export default GroundBooking
