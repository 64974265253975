import React, { Fragment, useContext } from 'react'
import moment from 'moment'
import { BookingContext } from './App'
import { currencyFormat } from './utils'
import useTotalCost from './useTotalCost'

const BookingSummary = ({
    bookingLoading,
    handleSubmit,
    isGovernment,
    isPwd,
    pwds,
    selectedGuestHouses = [],
    selectedDormitoryRooms = [],
    selectedConferenceHalls = [],
    selectedGrounds = [],
}) => {
    const {
        selectedPackage,
        packageType,
        unitType,
        adults,
        children,
        checkIn,
        checkOut,
    } = useContext(BookingContext)
    const selectedUnits = selectedGuestHouses.concat(
        selectedDormitoryRooms,
        selectedConferenceHalls,
        selectedGrounds
    )
    const bedsOccupied =
        selectedGuestHouses.reduce((acc, curr) => acc + curr.capacity, 0) +
        selectedDormitoryRooms.reduce((acc, curr) => acc + curr.capacity, 0)
    const conferenceHallOccupation = selectedConferenceHalls.reduce(
        (acc, curr) => acc + curr.capacity,
        0
    )
    const groundsOccupation = selectedGrounds.reduce(
        (acc, curr) => acc + curr.capacity,
        0
    )
    const numberOfHeads = [
        bedsOccupied,
        conferenceHallOccupation,
        groundsOccupation,
    ].sort((a, b) => b - a)[0]
    const dayDiff =
        checkOut.diff(checkIn, 'days') +
        (unitType === 'Conference Hall' || unitType === 'Ground' ? 1 : 0)
    const {
        loading,
        serviceFee,
        serviceFeeTotal,
        pwdDiscount,
        pwdDiscountTotal,
        governmentDiscount,
        governmentDiscountTotal,
        subTotal,
        totalCost,
    } = useTotalCost({
        selectedUnits,
        dayDiff,
        isGovernment,
        isPwd,
        pwds,
        numberOfHeads,
    })
    const unitRows = selectedUnits.map((u) => (
        <div key={u.id} className="d-flex align-items-center py-3">
            <div className="d-flex flex-column mr-auto">
                {u.unitType === 'Ground' ? (
                    <span className="font-weight-bolder">{`${u.name} (${u.description})`}</span>
                ) : (
                    <span className="font-weight-bolder">{u.name}</span>
                )}
            </div>
            {!selectedPackage && (
                <h4 className="mb-0 ml-3">
                    ₱{currencyFormat(u.capacityPrice * dayDiff)}
                </h4>
            )}
        </div>
    ))

    return (
        <div className="lighter-blue-card p-5">
            <div className="border-bottom-container pb-4 mb-5">
                <div className="d-flex align-items-center">
                    <h4 className="mr-auto">Booking schedule:</h4>
                    <p>
                        {moment(checkIn).format('ddd, MMMM DD, YYYY')}
                        {dayDiff > 1 ||
                        selectedGuestHouses.length > 0 ||
                        selectedDormitoryRooms.length > 0
                            ? ` - ${moment(checkOut).format(
                                  'ddd, MMMM DD, YYYY'
                              )}`
                            : ''}
                    </p>
                </div>
                {unitType === 'Conference Hall' ? (
                    <div className="d-flex align-items-center">
                        <h4 className="mr-auto">Guests</h4>
                        <p>
                            {adults} Guest{adults > 1 ? 's' : ''}
                        </p>
                    </div>
                ) : (
                    <div className="d-flex align-items-center">
                        <h4 className="mr-auto">Guests</h4>
                        <p>
                            {adults > 0
                                ? `${adults} adult${
                                      adults > 1 ? 's' : ''
                                  }`.concat(
                                      children > 0
                                          ? `, ${children} child${
                                                children > 1 ? 'ren' : ''
                                            }`
                                          : ''
                                  )
                                : children > 0
                                ? `${children} child${
                                      children > 1 ? 'ren' : ''
                                  }`
                                : ''}
                        </p>
                    </div>
                )}
            </div>
            <div className="border-bottom-container pb-5 mb-5">
                <div className="d-flex align-items-center">
                    <h4 className="mr-auto">No. of units:</h4>
                    <p>
                        {selectedUnits.length} Unit
                        {selectedUnits.length > 1 ? 's' : ''}
                    </p>
                </div>
                <h4 className="mb-4">Units for reservation:</h4>
                <div className="light-blue-card p-4">
                    <div
                        className="py-3 mb-4"
                        style={{ borderBottom: '1px solid var(--light-gray)' }}
                    >
                        {selectedPackage && packageType && (
                            <Fragment>
                                <div className="d-flex align-items-center">
                                    <div className="mr-auto">
                                        <h4 className="mb-0">
                                            {selectedPackage.name}
                                        </h4>
                                        <p className="font-size-sm mb-0">
                                            ₱{currencyFormat(packageType.price)}
                                            /head
                                        </p>
                                    </div>
                                    <h4 className="mb-0">x {numberOfHeads}</h4>
                                </div>
                            </Fragment>
                        )}
                        {unitRows}
                    </div>
                    {loading ? (
                        <div className="d-flex justify-content-center w-100">
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    ) : (
                        <Fragment>
                            {(serviceFeeTotal > 0 ||
                                governmentDiscountTotal > 0 ||
                                pwdDiscountTotal > 0) && (
                                <div
                                    className="pb-4 mb-4"
                                    style={{
                                        borderBottom:
                                            '1px solid var(--light-gray)',
                                    }}
                                >
                                    <div className="d-flex align-items-center mb-2">
                                        <div className="mr-auto">Sub Total</div>
                                        <div>₱{currencyFormat(subTotal)}</div>
                                    </div>
                                    {governmentDiscountTotal > 0 ? (
                                        <div className="d-flex align-items-center mb-2">
                                            <div className="d-flex align-items-center mr-auto">
                                                <span className="mr-1">
                                                    Government Agency Discount
                                                    {governmentDiscount &&
                                                    governmentDiscount.discountType ===
                                                        'percent'
                                                        ? ` (${governmentDiscount.amount}%)`
                                                        : ''}
                                                </span>
                                                <a
                                                    href="/help#discounts"
                                                    target="_blank"
                                                >
                                                    <span className="fe fe-help-circle"></span>
                                                </a>
                                            </div>
                                            <div>
                                                - ₱
                                                {currencyFormat(
                                                    governmentDiscountTotal
                                                )}
                                            </div>
                                        </div>
                                    ) : (
                                        pwdDiscountTotal > 0 && (
                                            <div className="d-flex align-items-center mb-2">
                                                <div className="d-flex align-items-center mr-auto">
                                                    <span className="mr-1">
                                                        Senior Citizen/PWD
                                                        Discount
                                                        {pwdDiscount &&
                                                        pwdDiscount.discountType ===
                                                            'percent'
                                                            ? ` (${pwdDiscount.amount}%)`
                                                            : ''}
                                                    </span>
                                                    <a
                                                        href="/help#discounts"
                                                        target="_blank"
                                                    >
                                                        <span className="fe fe-help-circle"></span>
                                                    </a>
                                                </div>
                                                <div>
                                                    - ₱
                                                    {currencyFormat(
                                                        pwdDiscountTotal
                                                    )}
                                                </div>
                                            </div>
                                        )
                                    )}
                                    {serviceFeeTotal > 0 && (
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex align-items-center mr-auto">
                                                <span className="mr-1">
                                                    Service Fee
                                                    {serviceFee &&
                                                    serviceFee.feeType ===
                                                        'percent'
                                                        ? ` (${serviceFee.amount}%)`
                                                        : ''}
                                                </span>
                                                <a
                                                    href="/help#fees"
                                                    target="_blank"
                                                >
                                                    <span className="fe fe-help-circle"></span>
                                                </a>
                                            </div>
                                            <div>
                                                ₱
                                                {currencyFormat(
                                                    serviceFeeTotal
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                            <div
                                className="d-flex align-items-center"
                                style={{ color: 'var(--blue)' }}
                            >
                                <h3 className="mr-auto">TOTAL</h3>
                                <h3>₱{currencyFormat(totalCost)}</h3>
                            </div>
                        </Fragment>
                    )}
                </div>
                {(governmentDiscountTotal > 0 || pwdDiscountTotal > 0) && (
                    <div className="d-flex align-items-center light-blue-card mt-5">
                        <span className="fe fe-info mr-3"></span>
                        <span className="font-size-sm mt-1">
                            Each government official, senior citizen, or PWD
                            included in the reservation must present valid ID
                            upon check-in to avail discounted rates.
                        </span>
                    </div>
                )}
            </div>
            {!loading && (
                <div className="d-flex justify-content-center">
                    {bookingLoading ? (
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    ) : (
                        <button
                            className="btn btn-primary"
                            style={{ width: '40%' }}
                            onClick={() => handleSubmit()}
                        >
                            Book
                        </button>
                    )}
                </div>
            )}
        </div>
    )
}

export default BookingSummary
