import React, { useEffect, createContext, useReducer } from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useLocation,
} from 'react-router-dom'
import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider,
    createHttpLink,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import MainNavigation from './MainNavigation'
import Home from './Home'
import About from './About'
import LodgingBooking from './LodgingBooking'
import ConferenceHallBooking from './ConferenceHallBooking'
import GroundBooking from './GroundBooking'
import PackageBooking from './PackageBooking'
import BookingDetails from './BookingDetails'
import Contact from './Contact'
import HelpCenter from './HelpCenter'
import 'react-image-lightbox/style.css'

const httpLink = createHttpLink({
    uri: '/graphql',
})

const authLink = setContext((_, { headers }) => {
    const token = document
        .querySelector('meta[name=csrf-token]')
        .getAttribute('content')
    return {
        headers: {
            ...headers,
            'X-CSRF-Token': token || '',
        },
    }
})

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
})

const ScrollToTop = () => {
    const { pathname, hash } = useLocation()

    useEffect(() => {
        if (!hash) {
            window.scrollTo(0, 0)
        }
    }, [pathname])

    return null
}

const initialState = {
    checkIn: null,
    checkOut: null,
    adults: 0,
    children: 0,
    unitType: null,
    selectedPackage: null,
    packageType: null,
}

const actions = {
    SET_CHECK_IN: 'SET_CHECK_IN',
    SET_CHECK_OUT: 'SET_CHECK_OUT',
    SET_ADULTS: 'SET_ADULTS',
    SET_CHILDREN: 'SET_CHILDREN',
    SET_UNIT_TYPE: 'SET_UNIT_TYPE',
    SET_SELECTED_PACKAGE: 'SET_SELECTED_PACKAGE',
    SET_PACKAGE_TYPE: 'SET_PACKAGE_TYPE',
    RESET_STATE: 'RESET_STATE',
}

const reducer = (state, action) => {
    const { type, payload } = action
    switch (type) {
        case actions.SET_CHECK_IN:
            return { ...state, checkIn: payload }
        case actions.SET_CHECK_OUT:
            return { ...state, checkOut: payload }
        case actions.SET_ADULTS:
            return { ...state, adults: payload }
        case actions.SET_CHILDREN:
            return { ...state, children: payload }
        case actions.SET_UNIT_TYPE:
            return { ...state, unitType: payload }
        case actions.SET_SELECTED_PACKAGE:
            return { ...state, selectedPackage: payload }
        case actions.SET_PACKAGE_TYPE:
            return { ...state, packageType: payload }
        case actions.RESET_STATE:
            return initialState
        default:
            return state
    }
}

export const BookingContext = createContext()

const ContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)

    const value = {
        checkIn: state.checkIn,
        checkOut: state.checkOut,
        adults: state.adults,
        children: state.children,
        unitType: state.unitType,
        selectedPackage: state.selectedPackage,
        packageType: state.packageType,
        setCheckIn: (payload) =>
            dispatch({ type: actions.SET_CHECK_IN, payload }),
        setCheckOut: (payload) =>
            dispatch({ type: actions.SET_CHECK_OUT, payload }),
        setAdults: (payload) => dispatch({ type: actions.SET_ADULTS, payload }),
        setChildren: (payload) =>
            dispatch({ type: actions.SET_CHILDREN, payload }),
        setUnitType: (payload) =>
            dispatch({ type: actions.SET_UNIT_TYPE, payload }),
        setSelectedPackage: (payload) =>
            dispatch({ type: actions.SET_SELECTED_PACKAGE, payload }),
        setPackageType: (payload) =>
            dispatch({ type: actions.SET_PACKAGE_TYPE, payload }),
        resetState: () => dispatch({ type: actions.RESET_STATE }),
    }

    return (
        <BookingContext.Provider value={value}>
            {children}
        </BookingContext.Provider>
    )
}

const App = () => {
    return (
        <ApolloProvider client={client}>
            <ContextProvider>
                <Router>
                    <ScrollToTop />
                    <MainNavigation />
                    <div
                        className="container-fluid d-flex align-stretch flex-column px-md-7-5"
                        style={{ minHeight: 'calc(100vh - 124px)' }}
                    >
                        <Switch>
                            <Route path="/about" component={About} />
                            <Route
                                path="/lodgingBooking"
                                component={LodgingBooking}
                            />
                            <Route
                                path="/conferenceHallBooking"
                                component={ConferenceHallBooking}
                            />
                            <Route
                                path="/groundBooking"
                                component={GroundBooking}
                            />
                            {/* <Route
                                path="/packageBooking"
                                component={PackageBooking}
                            /> */}
                            <Route
                                path="/bookingDetails"
                                component={BookingDetails}
                            />
                            <Route path="/help" component={HelpCenter} />
                            <Route path="/contact" component={Contact} />
                            <Route path="/">
                                <Home />
                            </Route>
                        </Switch>
                    </div>
                </Router>
            </ContextProvider>
        </ApolloProvider>
    )
}

export default App
