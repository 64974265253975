import React from 'react'

const UsageOfFacilities = () => (
    <div
        id="facilities"
        className="help-center-section"
        style={{
            borderBottom: 'none',
            paddingBottom: 0,
            marginBottom: 0,
        }}
    >
        <h2>Usage of Facilities</h2>
        <ul>
            <li>
                No live animals allowed on the premises (excluding service
                animals).
            </li>
            <li>
                All guests attending the event shall remain within the
                designated areas.
            </li>
            <li>
                DepEd Baguio Teachers Camp will not be held liable for any
                property left on the premises after the event’s conclusion.
            </li>
            <li>
                DepEd Baguio Teachers Camp facility staff will be permitted to
                enter the premises at any time during as well as after the
                event.
            </li>
            <li>
                DepEd Baguio Teachers Camp will be permitted to photograph the
                event during the event so long as such the event is not
                disrupted.
            </li>
            <li>
                DepEd Baguio Teachers Camp’s guest number may not exceed the
                allowed number of people in the facility at any time during the
                event.
            </li>
            <li>
                No smoking will be permitted in any area of the facility at any
                time.
            </li>
            <li>
                Alcohol consumption during the event requires prior approval by
                the DepEd Baguio Teachers Camp and will require additional
                security. No individuals below the legal age required for
                alcohol consumption shall be permitted to consume alcohol at any
                time at the Facility.
            </li>
            <li>
                DepEd Baguio Teachers Camp maintains general liability, fire,
                and property insurance. However, DepEd Baguio Teachers Camp’s
                insurance policies do not cover or protect against loss of
                guest’s property or damage or injury to DepEd Baguio Teachers
                Camp’s guests or their property.
            </li>
        </ul>
        <h3>Guest Houses, Cottages and Dormitories</h3>
        <ul>
            <li>
                Lodging is inclusive of toiletries, drinking water and towels
                for the actual number of guest/s.
            </li>
            <li>
                Cottages and Guesthouses are provided with cooking and dining
                utensils. Bringing out of camp properties is prohibited.
                Lost/damaged property will be charged to the guest.
            </li>
            <li>
                Cottages and Guesthouses are prohibited to be used as party
                venues.
            </li>
            <li>
                Guests should present an admission slip before occupying any
                facilities.
            </li>
            <li>
                Guests are advised to strictly follow House Rules posted on
                facility walls
            </li>
            <li>
                Guests should present their duly signed check-out clearance
                before leaving the facility. Additional payment will be charged
                for overstaying guests.
            </li>
            <li>
                Overbooking of a cottage/guesthouse/dorm room is limited to a
                maximum of 2 persons. Additional fees will be charged
                accordingly.
            </li>
        </ul>
        <h3>Conference Halls</h3>
        <ul>
            <li>
                Rental of the conference hall is from 8:00am – 6:00pm or
                10hours. Daily rate shall apply to rentals exceeding 10 hours.
            </li>
            <li>
                Stay-in guests are given priority in the use of the conference
                hall.
            </li>
            <li>
                Conference hall reservation is inclusive of Tables, chairs,
                sound system, LCD Projector and 100 pcs seat cover.
            </li>
            <li>
                Meals can be served in the conference hall. For delivered food,
                an additional corkage fee shall be charged. However, the corkage
                fee does not apply if the hall is rented out as a venue for
                social gathering (party) or special events.
            </li>
            <li>
                Hanging, posting stickers or seminar paraphernalia that will
                damage the walls and ceiling is strictly prohibited.
            </li>
            <li>
                Candles are strictly prohibited as an alternative light source
                in cases of power failure. However, candle lighting ceremonies
                during seminars are allowed, except at Benitez Hall. Use of
                candle protectors is required to prevent candle drippings on the
                floor.
            </li>
            <li>
                Guests are allowed to decorate for two (2) hours on the day
                before the scheduled day of activity provided that no
                electricity will be consumed. This is subject to conference hall
                availability.
            </li>
            <li>
                Use of electrical gadgets like computers, projectors, lights,
                sound system and the like in conference halls shall be charged
                accordingly.
            </li>
        </ul>
        <h3>Grounds</h3>
        <ul>
            <li>
                Stay-in guests have priority in availing daytime use of grounds.
            </li>
            <li>
                Activity organizers shall be held responsible for the behavior
                of the participants. The Camp is not responsible for any
                accidents, injuries or untoward incidents that may occur between
                or among the participants.
            </li>
            <li>
                Participants are responsible for the safety and care of their
                valuables.
            </li>
            <li>
                Bonfire activities are strictly prohibited in the campgrounds.
            </li>
        </ul>
    </div>
)

export default UsageOfFacilities
