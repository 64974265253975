import { useContext } from 'react'
import { gql, useQuery } from '@apollo/client'
import { BookingContext } from './App'

const GET_AVAILABLE_RATES = gql`
    query GetAvailableRates(
        $type: String!
        $checkIn: String!
        $checkOut: String!
    ) {
        getAvailableRates(unitType: $type, checkIn: $checkIn, checkOut: $checkOut) {
            unit {
                id
                unitType
                name
                capacity
            }
            rates {
                id
                name
                price
                description
            }
        }
    }
`

const useAvailableRates = () => {
    const { unitType, checkIn, checkOut } = useContext(BookingContext)
    const { loading, data } = useQuery(GET_AVAILABLE_RATES,
        {
            variables: {
                type: unitType,
                checkIn,
                checkOut,
            },
            fetchPolicy: 'network-only',
        }
    )

    return {
        loading,
        data,
    }
}

export default useAvailableRates
