import React from 'react'

const CheckInAndCheckOut = () => (
    <div id="checkin" className="help-center-section">
        <h2>Check-in and Check-out</h2>
        <p>
            Check-in and check-out shall only be allowed within Baguio Teachers
            Camp’s Office Hours (6:00am-11:00pm). Any payment of fees within
            Camp premises must likewise be made during office hours.
        </p>
        <h3>Guest Houses, Cottages and Dormitories</h3>
        <ul>
            <li>Check-in: On or before 2:00pm.</li>
            <li>Check-out: On or before 12:00pm.</li>
            <li>
                Early check-in may be allowed subject to cottage/room
                availability.
            </li>
        </ul>
        <h3>Conference Halls and Grounds</h3>
        <p>
            Check-in is only allowed one (1) hour before their reservation time
            for preparation, sanitation, facility inspection, and equipment
            drop-off.
        </p>
        <h3>Extension of Stay</h3>
        <p>
            All Extensions beyond the reserved period shall be charged
            equivalent to one (1) day.
        </p>
    </div>
)

export default CheckInAndCheckOut
