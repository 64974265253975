import React, { Fragment, useContext } from 'react'
import { BookingContext } from './App'
import Dropdown from './Dropdown'
import NumberCounter from './NumberCounter'
import { positiveNumber } from './utils'

const GuestsCounter = ({ handleChange, isMany, expanded, className }) => {
    const { adults, children, setAdults, setChildren } =
        useContext(BookingContext)

    const value =
        adults > 0
            ? `${adults} adult${adults > 1 ? 's' : ''}`.concat(
                  children > 0
                      ? `, ${children} child${children > 1 ? 'ren' : ''}`
                      : ''
              )
            : children > 0
            ? `${children} child${children > 1 ? 'ren' : ''}`
            : ''

    return (
        <Fragment>
            {isMany ? (
                <input
                    type="number"
                    className={`transparent-input ml-2 ${className}`}
                    value={adults || ''}
                    min={0}
                    onKeyDown={(e) => positiveNumber(e)}
                    onChange={(e) => {
                        handleChange()
                        setAdults(e.target.value)
                    }}
                    onWheel={(e) => e.target.blur()}
                    placeholder="Add Guests"
                />
            ) : expanded ? (
                <Fragment>
                    <ExpandedGuestsCounter
                        adults={adults}
                        children={children}
                        setAdults={setAdults}
                        setChildren={setChildren}
                        handleChange={handleChange}
                        expanded
                    />
                    <div
                        className="d-flex align-items-center pb-2 ml-2"
                        style={{ color: 'var(--gray)' }}
                    >
                        <span className="fe fe-info mr-3 mb-1"></span>
                        <span className="font-size-sm">
                            Children (ages 0-7) are free but do not occupy beds
                            on their own.
                        </span>
                    </div>
                </Fragment>
            ) : (
                <Dropdown
                    value={value}
                    placeholder="Add Guests"
                    body={
                        <ExpandedGuestsCounter
                            adults={adults}
                            children={children}
                            setAdults={setAdults}
                            setChildren={setChildren}
                            handleChange={handleChange}
                        />
                    }
                />
            )}
        </Fragment>
    )
}

const ExpandedGuestsCounter = ({
    adults,
    children,
    setAdults,
    setChildren,
    handleChange,
    expanded,
}) => {
    const handleAddAdult = () => {
        handleChange()
        setAdults(adults + 1)
    }

    const handleSubtractAdult = () => {
        handleChange()
        setAdults(adults - 1)
    }
    return (
        <div className={`${expanded ? 'pl-2' : ''}`}>
            <div className="d-flex align-items-center">
                <div className="mr-auto">
                    <span style={{ color: 'var(--black)' }}>Adults</span>
                </div>
                <NumberCounter
                    number={adults}
                    handleAdd={handleAddAdult}
                    handleSubtract={handleSubtractAdult}
                />
            </div>
            <div
                className={`d-flex align-items-center ${
                    expanded ? 'mb-3' : ''
                }`}
            >
                <div className="mr-auto">
                    <div>
                        <span style={{ color: 'var(--black)' }}>Children</span>
                    </div>
                    {!expanded && (
                        <p
                            className="mb-0"
                            style={{
                                fontSize: '50%',
                                color: 'var(--gray)',
                            }}
                        >
                            (ages 0-7)
                        </p>
                    )}
                </div>
                <NumberCounter
                    number={children}
                    handleAdd={() => setChildren(children + 1)}
                    handleSubtract={() => setChildren(children - 1)}
                />
            </div>
        </div>
    )
}

export default GuestsCounter
