import React, { Fragment, useContext, useState } from 'react'
import moment from 'moment'
import { BookingContext } from './App'
import Accordion from 'react-bootstrap/Accordion'
import LodgingUnitCounter from './LodgingUnitCounter'
import ConferenceHallUnitCounter from './ConferenceHallUnitCounter'
import PackageTypeDropdown from './PackageTypeDropdown'
import useAllAvailableUnits from './useAllAvailableUnits'

const MultipleBookingAccordion = ({
    selectedGuestHouses,
    setSelectedGuestHouses,
    selectedDormitoryRooms,
    setSelectedDormitoryRooms,
    selectedConferenceHalls,
    setSelectedConferenceHalls,
    seeAllGuestHouses,
    setSeeAllGuestHouses,
    seeAllDormitoryRooms,
    setSeeAllDormitoryRooms,
    isSelectedPackageWithGuestHouse,
    isSelectedPackageWithDormitoryRoom,
    isSelectedPackageWithLodging,
    isSelectedPackageWithConferenceHall,
}) => {
    const { selectedPackage, adults, checkIn, checkOut } =
        useContext(BookingContext)
    const { loading, data } = useAllAvailableUnits()
    const [isLodgingSelected, setIsLodgingSelected] = useState(false)
    const [isConferenceHallSelected, setIsConferenceHallSelected] =
        useState(false)
    const [isMealSelected, setIsMealSelected] = useState(false)
    const isDatesPresent = checkIn && checkOut
    const isMoreThanADay = isDatesPresent && checkOut.diff(checkIn, 'days') > 0
    const isCheckInToday =
        isDatesPresent && checkIn.get('date') === moment().get('date')
    const isDatesValid = isSelectedPackageWithLodging
        ? isMoreThanADay
        : isSelectedPackageWithConferenceHall
        ? !isCheckInToday
        : !isCheckInToday || isMoreThanADay
    const valid = isDatesValid && adults > 0

    return (
        <div className="mb-5">
            <h3>Available Units</h3>
            {!loading && valid && data ? (
                <Accordion className="booking-details-card">
                    {isMoreThanADay &&
                        (!selectedPackage || isSelectedPackageWithLodging) && (
                            <Fragment>
                                <Accordion.Toggle
                                    eventKey="0"
                                    className="accordion-header"
                                    style={{
                                        borderRadius: '10px 10px 0 0',
                                    }}
                                    onClick={() => {
                                        setIsConferenceHallSelected(false)
                                        setIsMealSelected(false)
                                        setIsLodgingSelected(!isLodgingSelected)
                                    }}
                                >
                                    Lodging
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                    <Accordion>
                                        {(!selectedPackage ||
                                            isSelectedPackageWithGuestHouse) && (
                                            <Fragment>
                                                <Accordion.Toggle
                                                    eventKey="0"
                                                    className="accordion-subheader"
                                                >
                                                    Guest House
                                                </Accordion.Toggle>
                                                <Accordion.Collapse
                                                    eventKey="0"
                                                    className="py-2 px-4"
                                                >
                                                    <LodgingUnitCounter
                                                        availableUnits={
                                                            data.guestHouses
                                                        }
                                                        selectedUnits={
                                                            selectedGuestHouses
                                                        }
                                                        setSelectedUnits={
                                                            setSelectedGuestHouses
                                                        }
                                                        seeAll={
                                                            seeAllGuestHouses
                                                        }
                                                        setSeeAll={
                                                            setSeeAllGuestHouses
                                                        }
                                                        givenUnitType="Guest House"
                                                    />
                                                </Accordion.Collapse>
                                            </Fragment>
                                        )}
                                        {(!selectedPackage ||
                                            isSelectedPackageWithDormitoryRoom) && (
                                            <Fragment>
                                                <Accordion.Toggle
                                                    eventKey="1"
                                                    className="accordion-subheader"
                                                >
                                                    Dormitory Room
                                                </Accordion.Toggle>
                                                <Accordion.Collapse
                                                    eventKey="1"
                                                    className="py-2 px-4"
                                                >
                                                    <LodgingUnitCounter
                                                        availableUnits={
                                                            data.dormitoryRooms
                                                        }
                                                        selectedUnits={
                                                            selectedDormitoryRooms
                                                        }
                                                        setSelectedUnits={
                                                            setSelectedDormitoryRooms
                                                        }
                                                        seeAll={
                                                            seeAllDormitoryRooms
                                                        }
                                                        setSeeAll={
                                                            setSeeAllDormitoryRooms
                                                        }
                                                        givenUnitType="Dormitory Room"
                                                    />
                                                </Accordion.Collapse>
                                            </Fragment>
                                        )}
                                    </Accordion>
                                </Accordion.Collapse>
                            </Fragment>
                        )}
                    {(!selectedPackage ||
                        isSelectedPackageWithConferenceHall) && (
                        <Fragment>
                            <Accordion.Toggle
                                eventKey="1"
                                className="accordion-header"
                                style={
                                    selectedPackage
                                        ? isSelectedPackageWithLodging
                                            ? {}
                                            : {
                                                  borderRadius: '10px 10px 0 0',
                                              }
                                        : isMoreThanADay
                                        ? isConferenceHallSelected
                                            ? {}
                                            : {
                                                  borderRadius: '0 0 10px 10px',
                                              }
                                        : isConferenceHallSelected
                                        ? { borderRadius: '10px 10px 0 0' }
                                        : {
                                              borderRadius: '10px',
                                          }
                                }
                                onClick={() => {
                                    setIsLodgingSelected(false)
                                    setIsMealSelected(false)
                                    setIsConferenceHallSelected(
                                        !isConferenceHallSelected
                                    )
                                }}
                            >
                                Conference Hall
                            </Accordion.Toggle>
                            <Accordion.Collapse
                                eventKey="1"
                                className="py-2 px-4"
                            >
                                <ConferenceHallUnitCounter
                                    availableUnits={data.conferenceHalls}
                                    selectedUnits={selectedConferenceHalls}
                                    setSelectedUnits={
                                        setSelectedConferenceHalls
                                    }
                                />
                            </Accordion.Collapse>
                        </Fragment>
                    )}
                    {selectedPackage && (
                        <Fragment>
                            <Accordion.Toggle
                                eventKey="2"
                                className="accordion-header"
                                style={
                                    isMealSelected
                                        ? {}
                                        : { borderRadius: '0 0 10px 10px' }
                                }
                                onClick={() => {
                                    setIsLodgingSelected(false)
                                    setIsConferenceHallSelected(false)
                                    setIsMealSelected(!isMealSelected)
                                }}
                            >
                                Meal
                            </Accordion.Toggle>
                            <Accordion.Collapse
                                eventKey="2"
                                className="py-2 px-4"
                            >
                                <PackageTypeDropdown />
                            </Accordion.Collapse>
                        </Fragment>
                    )}
                </Accordion>
            ) : (
                <div
                    className="booking-details-card d-flex align-items-center justify-content-center p-5"
                    style={{ height: '300px', color: 'var(--gray' }}
                >
                    {loading ? (
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    ) : valid || (!isDatesValid && adults > 0) ? (
                        <h3 className="mb-0">
                            Sorry there are no available units with the given
                            dates.
                        </h3>
                    ) : (
                        <div>
                            <h3 className="mb-2">
                                Please fill up search fields.
                            </h3>
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

export default MultipleBookingAccordion
