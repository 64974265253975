import React, { Fragment, useContext, useState } from 'react'
import { BookingContext } from './App'
import BookingSearch from './BookingSearch'
import UnitTypeDropdown from './UnitTypeDropdown'
import LodgingUnitTabs from './LodgingUnitTabs'
import ReservationSummary from './ReservationSummary'
import UnitsLightbox from './UnitsLightbox'
import useAvailableUnits from './useAvailableUnits'

const LodgingBooking = () => {
    const { unitType, adults, checkIn, checkOut } = useContext(BookingContext)
    const searchComplete =
        unitType &&
        (unitType === 'Guest House' || unitType === 'Dormitory Room') &&
        checkIn &&
        checkOut
    const valid =
        searchComplete && checkOut.diff(checkIn, 'days') >= 1 && adults > 0
    const { loading, data } = useAvailableUnits()
    const searchingDisabled = loading || !valid || !data
    const [selectedUnits, setSelectedUnits] = useState([])
    const [seeAll, setSeeAll] = useState(false)

    const handleSearchChange = () => {
        setSelectedUnits([])
        setSeeAll(false)
    }

    return (
        <Fragment>
            <div className="mt-4 mb-5">
                <UnitsLightbox unitType={unitType} />
            </div>
            <div className="d-flex flex-wrap">
                <div className="col-12 col-xl-6 px-0">
                    <div className="border-bottom-container">
                        <h1>Lodgings</h1>
                    </div>
                    <div className="border-bottom-container">
                        <h3>About our lodgings</h3>
                        <p style={{ color: 'var(--gray)' }}>
                            Beyond its role in the long journey of Philippine
                            education, the Baguio Teachers Camp remains to offer
                            that old familiar ambiance of Baguio City that
                            travelers and guests hauntingly long for. A
                            comfortable place of rest, of study, and leisure,
                            for individuals, circle of friends, and families,
                            while keeping in touch with Baguio’s glory and
                            antiquity.
                        </p>
                    </div>
                    <h3 id="search">Add details</h3>
                    <p className="mb-4" style={{ color: 'var(--gray)' }}>
                        Please fill up search fields.
                    </p>
                    <div className="booking-details-card pt-3 mb-5">
                        <UnitTypeDropdown
                            handleChange={handleSearchChange}
                            compact
                            isLodgingOnly
                        />
                        <BookingSearch
                            handleChange={handleSearchChange}
                            compact
                            guestsExpanded
                        />
                    </div>
                    <div className="mb-5">
                        <LodgingUnitTabs
                            availableUnits={data ? data.getAvailableUnits : []}
                            selectedUnits={selectedUnits}
                            setSelectedUnits={setSelectedUnits}
                            seeAll={seeAll}
                            setSeeAll={setSeeAll}
                            disabled={searchingDisabled}
                        />
                        {searchingDisabled && (
                            <div
                                className="booking-details-card d-flex align-items-center justify-content-center p-5"
                                style={{
                                    height: '300px',
                                    color: 'var(--gray)',
                                }}
                            >
                                {loading ? (
                                    <div
                                        className="spinner-border"
                                        role="status"
                                    >
                                        <span className="sr-only">
                                            Loading...
                                        </span>
                                    </div>
                                ) : valid ? (
                                    <h3 className="mb-0">
                                        Sorry there are no available units with
                                        the given search fields.
                                    </h3>
                                ) : (
                                    <div>
                                        {!searchComplete && (
                                            <h3 className="mb-2">
                                                Please fill up search fields.
                                            </h3>
                                        )}
                                        {checkIn &&
                                            checkOut &&
                                            checkOut.diff(checkIn, 'days') <
                                                1 && (
                                                <h3 className="mb-2">
                                                    Minimum number of nights is
                                                    one.
                                                </h3>
                                            )}
                                        {adults <= 0 && (
                                            <h3 className="mb-0">
                                                There should be at least one
                                                adult guest.
                                            </h3>
                                        )}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
                {valid && (
                    <div className="col-12 col-xl-6 p-0 pl-xl-5">
                        <ReservationSummary
                            selectedGuestHouses={
                                unitType === 'Guest House' ? selectedUnits : []
                            }
                            selectedDormitoryRooms={
                                unitType === 'Dormitory Room'
                                    ? selectedUnits
                                    : []
                            }
                        />
                    </div>
                )}
            </div>
        </Fragment>
    )
}

export default LodgingBooking
