import React, { Fragment, useState, useContext } from 'react'
import { DateRangePicker } from 'react-dates'
import moment from 'moment'
import { BookingContext } from './App'
import GuestsCounter from './GuestsCounter'

const BookingSearch = ({
    compact,
    minimumNights = 1,
    dayOffset = 0,
    handleChange = () => {},
    withManyGuests,
    guestsExpanded,
    guestsClassName,
}) => {
    const { checkIn, checkOut, setCheckIn, setCheckOut } =
        useContext(BookingContext)
    const [focusedInput, setFocusedInput] = useState(null)

    const handleOnDatesChange = ({ startDate, endDate }) => {
        handleChange()
        setCheckIn(startDate)
        setCheckOut(endDate)
    }

    return (
        <Fragment>
            <div
                style={
                    compact
                        ? {
                              borderBottom: '1px solid var(--light-gray)',
                              padding: '0 0.7rem',
                              marginBottom: '0.75rem',
                          }
                        : { marginRight: 'auto' }
                }
            >
                <h3
                    className="ml-3"
                    style={{ marginTop: '0.4rem', marginBottom: '-0.125rem' }}
                >
                    Dates
                </h3>
                <div className="custom-calendar">
                    <DateRangePicker
                        isOutsideRange={(day) =>
                            day.isBefore(moment().add(dayOffset, 'days'), 'day')
                        }
                        minimumNights={minimumNights}
                        startDate={checkIn}
                        startDateId="checkIn"
                        endDate={checkOut}
                        endDateId="checkOut"
                        onDatesChange={({ startDate, endDate }) =>
                            handleOnDatesChange({ startDate, endDate })
                        }
                        focusedInput={focusedInput}
                        onFocusChange={(focusedInput) =>
                            setFocusedInput(focusedInput)
                        }
                        startDatePlaceholderText="Check in"
                        endDatePlaceholderText="Check out"
                        hideKeyboardShortcutsPanel
                        noBorder
                    />
                </div>
            </div>
            <div
                className={`${
                    compact ? 'd-none' : ''
                } vertical-divider mt-2 mr-2 mb-3`}
            ></div>
            <div
                style={
                    compact
                        ? {
                              padding: '0 1rem',
                          }
                        : { marginRight: 'auto' }
                }
            >
                <h3 className="mb-2 ml-2">Guests</h3>
                <GuestsCounter
                    handleChange={handleChange}
                    isMany={withManyGuests}
                    expanded={guestsExpanded}
                    className={guestsClassName}
                />
            </div>
        </Fragment>
    )
}

export default BookingSearch
