import React from 'react'

const Discounts = () => (
    <div id="discounts" className="help-center-section">
        <h2>Discounts</h2>
        <h3>Government Discount</h3>
        <p>
            Bookings made by Government guests are eligible for a 20% discount,
            applied to all facilities (guest houses, cottages, dormitories,
            conference halls, and grounds). Discount will apply upon
            presentation of valid ID upon check-in.
        </p>
        <h3>Government Guests</h3>
        <ul>
            <li>
                Government guests are Individuals and Organizations meeting any
                of the following criteria. All other guests are classified as
                Non-Government.
            </li>
            <li>
                Teachers, Students, and School Officials attending
                DepEd-sponsored seminars, training, and conventions DepEd staff
                and their companions, provided a valid DepEd Identification Card
                is presented
            </li>
            <li>
                Staff of DepEd-accredited private schools (including Senior
                High), provided a valid identification card from their school is
                presented
            </li>
            <li>
                Students on Educational Tours and Conferences endorsed by the
                Department of Education
            </li>
            <li>
                Government agencies conducting seminars, conferences, and
                training
            </li>
            <li>
                Other Government employees, provided their Government Agency
                Identification Card is presented
            </li>
        </ul>
        <h3>Senior Citizen and Person with Disability Discount</h3>
        <ul>
            <li>
                Bookings made by Senior Citizens and Persons with Disability are
                eligible for a 20% discount, applied to all lodgings (guest
                houses, cottages, and dormitories).
            </li>
            <li>
                If the booking is made by a person who doesn’t qualify for
                either discount, the 20% lodging discount will still apply, but
                only for the portion occupied by the Senior Citizen or PWD.
            </li>
            <li>
                Discount will apply upon presentation of Senior Citizen or PWD
                ID upon check-in.
            </li>
        </ul>
        <h3>Children (0-7 years old)</h3>
        <p>
            All Children are free of charge provided they are sleeping with
            their parents.
        </p>
    </div>
)

export default Discounts
