import React, { Fragment, useState } from 'react'
import Lightbox from 'react-image-lightbox'
import dorm1 from '../../assets/images/dorm-banner-1.png'
import dorm2 from '../../assets/images/dorm-banner-2.jpeg'
import dorm3 from '../../assets/images/dorm-banner-3.jpeg'
import guestHouse1 from '../../assets/images/banner.jpg'
import guestHouse2 from '../../assets/images/welcome-to-btc-img.jpg'
import guestHouse3 from '../../assets/images/cottages-and-guesthouses.jpg'
import conferenceHall1 from '../../assets/images/conference-halls-1.png'
import conferenceHall2 from '../../assets/images/conference-halls-2.png'
import conferenceHall3 from '../../assets/images/conference-halls-3.jpeg'
import ground1 from '../../assets/images/centennial-ground.JPG'
import ground2 from '../../assets/images/thomasites-edited.jpg'
import ground3 from '../../assets/images/ampitheater.JPG'
import Image from 'react-bootstrap/Image'

const dormImages = [dorm1, dorm2, dorm3]
const guestHouseImages = [guestHouse1, guestHouse2, guestHouse3]
const conferenceHallImages = [conferenceHall1, conferenceHall2, conferenceHall3]
const groundImages = [ground1, ground2, ground3]

const UnitsLightbox = ({ unitType }) => {
    const [index, setIndex] = useState(0)
    const [isOpen, setIsOpen] = useState(false)
    const images =
        unitType === 'Dormitory Room'
            ? dormImages
            : unitType === 'Conference Hall'
            ? conferenceHallImages
            : unitType === 'Ground'
            ? groundImages
            : guestHouseImages

    const handleOpen = (i) => {
        setIndex(i)
        setIsOpen(true)
    }

    return (
        <Fragment>
            {isOpen && (
                <Lightbox
                    mainSrc={images[index]}
                    nextSrc={images[(index + 1) % images.length]}
                    prevSrc={
                        images[(index + images.length - 1) % images.length]
                    }
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() =>
                        setIndex((index + images.length - 1) % images.length)
                    }
                    onMoveNextRequest={() =>
                        setIndex((index + 1) % images.length)
                    }
                />
            )}
            <div className="d-none d-md-flex" style={{ height: '400px' }}>
                <button
                    className="btn-link-transparent flex-3 pl-0"
                    onClick={() => handleOpen(0)}
                >
                    <Image
                        src={images[0]}
                        className="object-fit-cover h-100 w-100"
                        rounded
                    />
                </button>
                <div className="flex-1">
                    <button
                        className="btn-link-transparent h-50 w-100 pr-0 pb-2"
                        onClick={() => handleOpen(1)}
                    >
                        <Image
                            src={images[1]}
                            className="object-fit-cover h-100 w-100"
                            rounded
                        />
                    </button>
                    <button
                        className="btn-link-transparent h-50 w-100 pr-0 pt-2"
                        onClick={() => handleOpen(2)}
                    >
                        <Image
                            src={images[2]}
                            className="object-fit-cover h-100 w-100"
                            rounded
                        />
                    </button>
                </div>
            </div>
            <div className="d-block d-md-none" style={{ height: '300px' }}>
                <button
                    className="btn-link-transparent h-100 w-100 px-0"
                    onClick={() => handleOpen(index)}
                >
                    <Image
                        src={images[index]}
                        className="object-fit-cover h-100 w-100"
                        rounded
                    />
                </button>
            </div>
        </Fragment>
    )
}

export default UnitsLightbox
