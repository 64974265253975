import React, { Fragment, useContext, useState } from 'react'
import { BookingContext } from './App'
import Form from 'react-bootstrap/Form'
import NumberCounter from './NumberCounter'
import { currencyFormat } from './utils'

const LodgingUnitSearchCounter = ({
    availableUnits,
    selectedUnits,
    setSelectedUnits,
    seeAll,
    setSeeAll,
    setIsSearch,
}) => {
    const { unitType, adults } = useContext(BookingContext)
    const [searchedUnits, setSearchedUnits] = useState([])
    const [searchText, setSearchText] = useState('')

    const searchUnits = (search) => {
        setSeeAll(false)
        setSearchText(search)
        setSearchedUnits(
            search
                ? availableUnits.filter((u) =>
                      u.fullName.toLowerCase().includes(search.toLowerCase())
                  )
                : []
        )
    }

    const handleAddUnit = (unit) => {
        setSelectedUnits(
            selectedUnits.concat({ ...unit, unitType, name: unit.fullName })
        )
    }

    const handleSubtractUnit = (unit) => {
        setSelectedUnits(selectedUnits.filter((u) => u.id !== unit.id))
    }

    const unitRows = searchedUnits.map((unit) => {
        const selected = selectedUnits.some((u) => u.id === unit.id)

        return (
            <div
                key={unit.id}
                className="booking-details-card d-flex align-items-center py-3 px-4 mb-3"
                style={selected ? { borderColor: 'var(--primary' } : {}}
            >
                <div className="d-flex flex-column mr-auto">
                    <span className="font-weight-bolder">{unit.fullName}</span>
                    <span>
                        {unit.capacity} beds
                        {unitType === 'Guest House'
                            ? ` · ${unit.numberOfRooms} Room${
                                  unit.numberOfRooms > 1 ? 's' : ''
                              }`
                            : ''}
                        {unitType === 'Guest House'
                            ? ` · ${unit.numberOfBathrooms} Bathroom${
                                  unit.numberOfBathrooms > 1 ? 's' : ''
                              }`
                            : unit.numberOfBathrooms > 0
                            ? ' (with own Bathroom)'
                            : ' (shared Bathroom)'}
                    </span>
                    <span>₱{currencyFormat(unit.capacityPrice)}/night</span>
                </div>
                <NumberCounter
                    number={selected ? 1 : 0}
                    handleAdd={() => handleAddUnit(unit)}
                    handleSubtract={() => handleSubtractUnit(unit)}
                    isAddDisabled={selected || selectedUnits.length >= adults}
                />
            </div>
        )
    })

    const topUnitRows = unitRows.slice(0, 8).concat(
        <div
            key="seeAll"
            className="d-flex align-items-center justify-content-center py-3"
        >
            <button className="btn btn-link" onClick={() => setSeeAll(true)}>
                See more options
            </button>
        </div>
    )

    return (
        <div>
            <div className="booking-details-card p-5 mb-4">
                <Form.Label as="h4">Unit Name and/or Building Name</Form.Label>
                <Form.Control
                    type="text"
                    value={searchText}
                    placeholder="Search here for your preferred unit(s)"
                    onChange={(e) => searchUnits(e.target.value)}
                />
            </div>
            {searchedUnits.length > 0 ? (
                <Fragment>
                    <div className="ml-3">
                        <h4>Please select units you want to reserve.</h4>
                        <p className="mb-4" style={{ color: 'var(--gray)' }}>
                            If your preferred unit isn't listed, they may be
                            unavailable at the given dates.
                        </p>
                    </div>
                    {seeAll || unitRows.length <= 8 ? unitRows : topUnitRows}
                </Fragment>
            ) : (
                searchText && (
                    <div
                        className="booking-details-card d-flex align-items-center justify-content-center p-5"
                        style={{
                            height: '300px',
                            color: 'var(--gray)',
                        }}
                    >
                        <h3 className="mb-0">
                            Sorry there are no matches found. Try changing the
                            search fields or find non-specific{' '}
                            <a
                                href="#/"
                                className="text-decoration-underline"
                                onClick={() => setIsSearch(false)}
                                style={{ color: 'var(--primary)' }}
                            >
                                available units
                            </a>
                            .
                        </h3>
                    </div>
                )
            )}
        </div>
    )
}

export default LodgingUnitSearchCounter
