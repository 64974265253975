import React, { Fragment, useState, useEffect } from 'react'
import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider,
    createHttpLink,
    gql,
    useQuery,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { DateRangePicker } from 'react-dates'
import chevron from '../../assets/images/chevron-right.svg'

const httpLink = createHttpLink({
    uri: '/graphql',
})

const authLink = setContext((_, { headers }) => {
    const token = document
        .querySelector('meta[name=csrf-token]')
        .getAttribute('content')
    return {
        headers: {
            ...headers,
            'X-CSRF-Token': token || '',
        },
    }
})

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
})

const GET_AVAILABLE_UNITS = gql`
    query GetAvailableUnits(
        $type: String!
        $startDate: String!
        $endDate: String!
    ) {
        getDailyAvailableUnits(
            unitTypes: [$type]
            startDate: $startDate
            endDate: $endDate
        ) {
            date
            available
            occupied
        }
        getAvailableUnits(
            type: $type
            checkIn: $startDate
            checkOut: $endDate
        ) {
            id
            name
            capacity
            capacityPriceDurationUnit
            parent {
                name
            }
        }
    }
`

const dateOptions = {
    weekday: 'short',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
}

const Dashboard = () => {
    const [unitType, setUnitType] = useState('')
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [focusedInput, setFocusedInput] = useState(null)
    const { loading, data } = useQuery(GET_AVAILABLE_UNITS, {
        variables: {
            type: unitType,
            startDate,
            endDate,
        },
        fetchPolicy: 'network-only',
    })
    const valid = unitType && startDate && endDate
    const hasAvailableUnitsWithinRange =
        data && data.getAvailableUnits && data.getAvailableUnits.length > 0

    const handleOnDatesChange = ({ startDate, endDate }) => {
        setStartDate(startDate)
        setEndDate(endDate)
    }

    const dailyCards =
        valid &&
        data &&
        data.getDailyAvailableUnits &&
        data.getDailyAvailableUnits.map((d) => (
            <div className="gray-card-container" key={d.date}>
                <div className="gray-card">
                    <h4>
                        {new Date(d.date).toLocaleDateString(
                            undefined,
                            dateOptions
                        )}
                    </h4>
                    {d.occupied > 0 && (
                        <a
                            className="gray-sticker"
                            style={{ marginBottom: '0.375rem' }}
                            href={`/admin/dashboard/units?unit_types[]=${unitType}&start_date=${d.date}`}
                        >
                            {d.occupied} Occupied <img src={chevron} />
                        </a>
                    )}
                    {d.available > 0 && (
                        <a
                            className="green-sticker"
                            href={`/admin/dashboard/units?unit_types[]=${unitType}&start_date=${d.date}&is_available=1`}
                        >
                            {d.available} Available <img src={chevron} />
                        </a>
                    )}
                </div>
            </div>
        ))

    const unitRows =
        valid &&
        hasAvailableUnitsWithinRange &&
        data.getAvailableUnits.map((u) => (
            <tr key={u.id}>
                {u.parent && u.parent.name ? (
                    <td>
                        {u.parent.name} {u.name}
                    </td>
                ) : (
                    <td>{u.name}</td>
                )}
                <td>{u.capacity}</td>
                <td>
                    <a
                        href={`/admin/bookings/new?check_in=${startDate.format(
                            'YYYY-MM-DD'
                        )}&check_out=${endDate.format('YYYY-MM-DD')}&unit_id=${
                            u.id
                        }&duration=${u.capacityPriceDurationUnit}`}
                    >
                        Book
                    </a>
                </td>
            </tr>
        ))

    // Using jQuery here due to select2-rails
    useEffect(() => {
        $('#unitType').on('change', (e) => setUnitType(e.target.value))
    }, [])

    return (
        <Fragment>
            <h2>Available Units</h2>
            <div
                style={{
                    display: 'flex',
                    backgroundColor: '#f2f2f2',
                    padding: '1.5rem',
                    marginBottom: '2.25rem',
                }}
            >
                <div style={{ flex: 1 }}>
                    <div style={{ marginBottom: '0.375rem' }}>
                        <label>Unit Type</label>
                    </div>
                    <select id="unitType" style={{ width: '80%' }}>
                        <option></option>
                        <option value="Dormitory Room">Dormitory Room</option>
                        <option value="Guest House">Guest House</option>
                        <option value="Conference Hall">Conference Hall</option>
                        <option value="Ground">Ground</option>
                    </select>
                </div>
                <div style={{ flex: 1 }}>
                    <div style={{ marginBottom: '0.375rem' }}>
                        <label>Dates</label>
                    </div>
                    <div className="custom-calendar">
                        <DateRangePicker
                            minimumNights={0}
                            startDate={startDate}
                            startDateId="startDate"
                            endDate={endDate}
                            endDateId="endDate"
                            onDatesChange={({ startDate, endDate }) =>
                                handleOnDatesChange({ startDate, endDate })
                            }
                            focusedInput={focusedInput}
                            onFocusChange={(focusedInput) =>
                                setFocusedInput(focusedInput)
                            }
                            startDatePlaceholderText="Start Date"
                            endDatePlaceholderText="End Date"
                            hideKeyboardShortcutsPanel
                            noBorder
                        />
                    </div>
                </div>
            </div>
            <div
                style={{
                    display: 'flex',
                    height: '50vh',
                    marginBottom: '4.5rem',
                }}
            >
                {loading || !valid ? (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '50vh',
                            width: '100%',
                        }}
                    >
                        {loading ? (
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        ) : (
                            <p style={{ color: '#7d7d7d' }}>
                                Please fill up search fields.
                            </p>
                        )}
                    </div>
                ) : (
                    <Fragment>
                        <div
                            style={{
                                display: 'flex',
                                flex: 2,
                                flexDirection: 'column',
                                paddingRight: '1.5rem',
                            }}
                        >
                            <h3>Available Units per Day</h3>
                            <div
                                style={{
                                    display: 'grid',
                                    gridGap: '0.375rem',
                                    gridTemplateColumns: 'repeat(3, 1fr)',
                                    border: '1px solid #cfcfcf',
                                    padding: '0.75rem',
                                    overflowY: 'auto',
                                }}
                            >
                                {dailyCards}
                            </div>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flex: 1,
                                flexDirection: 'column',
                            }}
                        >
                            <h3>Available Units within Selected Range</h3>
                            <div
                                style={{
                                    overflowY: 'auto',
                                }}
                            >
                                {hasAvailableUnitsWithinRange ? (
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Capacity</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>{unitRows}</tbody>
                                    </table>
                                ) : (
                                    <p
                                        style={{
                                            textAlign: 'center',
                                            color: '#7d7d7d',
                                        }}
                                    >
                                        No available units within selected
                                        range.
                                    </p>
                                )}
                            </div>
                        </div>
                    </Fragment>
                )}
            </div>
        </Fragment>
    )
}

const ActiveAdminDashboard = () => (
    <ApolloProvider client={client}>
        <Dashboard />
    </ApolloProvider>
)

export default ActiveAdminDashboard
