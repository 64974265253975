import React, { Fragment, useContext } from 'react'
import { BookingContext } from './App'
import NumberCounter from './NumberCounter'
import { currencyFormat } from './utils'

const LodgingUnitCounter = ({
    availableUnits,
    selectedUnits,
    setSelectedUnits,
    seeAll,
    setSeeAll,
    givenUnitType,
}) => {
    const { unitType: contextUnitType, adults } = useContext(BookingContext)
    const unitType = givenUnitType || contextUnitType

    const hasEqualUnitProperties = (a, b) => {
        return (
            a.capacity === b.capacity &&
            a.numberOfRooms === b.numberOfRooms &&
            a.numberOfBathrooms === b.numberOfBathrooms &&
            a.capacityPrice === b.capacityPrice
        )
    }

    const units = availableUnits
        ? availableUnits.reduce((acc, curr) => {
              const index = acc.findIndex((a) =>
                  hasEqualUnitProperties(a, curr)
              )
              if (index < 0) {
                  return acc.concat({
                      unitType: curr.unitType,
                      name: `${curr.capacity} beds`
                          .concat(
                              unitType === 'Guest House'
                                  ? ` · ${curr.numberOfRooms} Room${
                                        curr.numberOfRooms > 1 ? 's' : ''
                                    }`
                                  : ''
                          )
                          .concat(
                              unitType === 'Guest House'
                                  ? ` · ${curr.numberOfBathrooms} Bathroom${
                                        curr.numberOfBathrooms > 1 ? 's' : ''
                                    }`
                                  : curr.numberOfBathrooms > 0
                                  ? ' (with own Bathroom)'
                                  : ' (shared Bathroom)'
                          ),
                      capacity: curr.capacity,
                      numberOfRooms: curr.numberOfRooms,
                      numberOfBathrooms: curr.numberOfBathrooms,
                      capacityPrice: curr.capacityPrice,
                      count: 1,
                      ids: [curr.id],
                  })
              } else {
                  let newAcc = acc
                  newAcc[index] = {
                      ...acc[index],
                      count: acc[index].count + 1,
                      ids: acc[index].ids.concat(curr.id),
                  }
                  return newAcc
              }
          }, [])
        : []

    const minUnits = units
        .filter((unit) =>
            unitType === 'Guest House'
                ? unit.capacity < adults
                : unit.capacity <= adults
        )
        .sort((a, b) =>
            a.capacity === b.capacity
                ? a.capacityPrice - b.capacityPrice
                : b.capacity - a.capacity
        )
    const maxUnits = units
        .filter((unit) =>
            unitType === 'Guest House'
                ? unit.capacity >= adults
                : unit.capacity > adults
        )
        .sort((a, b) =>
            a.capacity === b.capacity
                ? a.capacityPrice - b.capacityPrice
                : a.capacity - b.capacity
        )

    const sortedUnits =
        unitType === 'Guest House'
            ? maxUnits.concat(minUnits)
            : minUnits.concat(maxUnits)

    const handleAddUnit = (unit) => {
        const index = selectedUnits.reduce(
            (acc, curr) => (hasEqualUnitProperties(curr, unit) ? acc + 1 : acc),
            0
        )
        setSelectedUnits(
            selectedUnits
                .concat({
                    unitType: unit.unitType,
                    name: unit.name,
                    capacity: unit.capacity,
                    capacityPrice: unit.capacityPrice,
                    numberOfRooms: unit.numberOfRooms,
                    numberOfBathrooms: unit.numberOfBathrooms,
                    id: unit.ids[index],
                })
                .sort((a, b) => {
                    return a.capacity < b.capacity
                        ? -1
                        : a.capacity > b.capacity
                        ? 1
                        : a.capacityPrice - b.capacityPrice
                })
        )
    }

    const handleSubtractUnit = (unit) => {
        const selectedUnit = [...selectedUnits]
            .reverse()
            .find((u) => hasEqualUnitProperties(u, unit))
        if (selectedUnit) {
            const newSelectedUnits = selectedUnits.filter(
                (u) => u.id !== selectedUnit.id
            )
            setSelectedUnits(newSelectedUnits)
        }
    }

    const unitRows = sortedUnits.map((unit, index) => {
        const count = selectedUnits.reduce(
            (acc, curr) => (hasEqualUnitProperties(curr, unit) ? acc + 1 : acc),
            0
        )
        return (
            <div
                key={'Unit' + index}
                className="booking-details-card d-flex align-items-center py-3 px-4 mb-3"
                style={count > 0 ? { borderColor: 'var(--primary' } : {}}
            >
                <div className="d-flex flex-column mr-auto">
                    <span className="font-weight-bolder">{unit.name}</span>
                    <span>
                        ₱{currencyFormat(unit.capacityPrice)}/night ·{' '}
                        {unit.count} unit{unit.count > 1 ? 's' : ''} left
                    </span>
                </div>
                <NumberCounter
                    number={count}
                    handleAdd={() => handleAddUnit(unit)}
                    handleSubtract={() => handleSubtractUnit(unit)}
                    isAddDisabled={
                        selectedUnits.length >= adults || count >= unit.count
                    }
                />
            </div>
        )
    })

    const topUnitRows = unitRows.slice(0, 8).concat(
        <div
            key="seeAll"
            className="d-flex align-items-center justify-content-center py-3"
        >
            <button className="btn btn-link" onClick={() => setSeeAll(true)}>
                See more options
            </button>
        </div>
    )

    return <Fragment>{seeAll ? unitRows : topUnitRows}</Fragment>
}

export default LodgingUnitCounter
