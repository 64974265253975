import React from 'react'

const Reservations = () => (
    <div id="reservations" className="help-center-section">
        <h2>Reservations</h2>
        <p>
            <span>
                Reservations for use of all Baguio Teachers Camp facilities
                (guest houses, cottages, dormitories, conference halls, dining
                halls, and grounds) are to be arranged through the Baguio
                Teachers Camp website. Any questions or concerns regarding these
                arrangements may be coordinated by telephone at (074) 4423517 &
                4427149 or email at{' '}
            </span>
            <a href="mailto:clientrelations_deped100@yahoo.com">
                clientrelations_deped100@yahoo.com
            </a>
            .
        </p>
        <ul>
            <li>
                Reservations are held up to 6:00pm of the expected check in
                date. Otherwise, the camp reserves the right to release the
                reserved facility. Any delays in guest arrival should be
                coordinated immediately with Baguio Teachers Camp by telephone
                or email.
            </li>
            <li>
                DepEd-sponsored seminars are advised to inform and reserve their
                scheduled activities at least six (6) months early to avoid
                conflicts of schedule with other reserved guests.
            </li>
            <li>
                Non-DepEd clients with outstanding balance to the Camp cannot
                avail use of any Camp facilities.
            </li>
        </ul>
    </div>
)

export default Reservations
