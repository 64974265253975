import React, { Fragment, useContext, useState } from 'react'
import { gql, useQuery } from '@apollo/client'
import { BookingContext } from './App'
import BookingSearch from './BookingSearch'
import MultipleBookingAccordion from './MultipleBookingAccordion'
import ChildrenCounter from './ChildrenCounter'
import ReservationSummary from './ReservationSummary'
import Dropdown from './Dropdown'

const GET_PACKAGES = gql`
    {
        packages {
            id
            name
            description
            guestCount
            packageInclusions {
                name
                category
            }
            rates {
                id
                name
                price
                description
            }
        }
    }
`

const PackageBooking = () => {
    const { selectedPackage, setSelectedPackage } = useContext(BookingContext)
    const { loading, data } = useQuery(GET_PACKAGES, {
        fetchPolicy: 'network-only',
    })
    const [selectedGuestHouses, setSelectedGuestHouses] = useState([])
    const [selectedDormitoryRooms, setSelectedDormitoryRooms] = useState([])
    const [selectedConferenceHalls, setSelectedConferenceHalls] = useState([])
    const [seeAllGuestHouses, setSeeAllGuestHouses] = useState(false)
    const [seeAllDormitoryRooms, setSeeAllDormitoryRooms] = useState(false)
    const packageOptions = [{ label: 'None', value: null }]
    const isSelectedPackageWithGuestHouse =
        selectedPackage &&
        selectedPackage.packageInclusions.some((p) => p.name === 'Guest House')
    const isSelectedPackageWithDormitoryRoom =
        selectedPackage &&
        selectedPackage.packageInclusions.some(
            (p) => p.name === 'Dormitory Room'
        )
    const isSelectedPackageWithLodging =
        isSelectedPackageWithGuestHouse || isSelectedPackageWithDormitoryRoom
    const isSelectedPackageWithConferenceHall =
        selectedPackage &&
        selectedPackage.packageInclusions.some(
            (p) => p.name === 'Conference Hall'
        )

    const handleSearchChange = () => {
        setSelectedGuestHouses([])
        setSelectedDormitoryRooms([])
        setSelectedConferenceHalls([])
        setSeeAllGuestHouses(false)
        setSeeAllDormitoryRooms(false)
    }

    return (
        <div className="d-flex flex-wrap mt-6">
            <div className="col-12 col-xl-6 px-0">
                <div className="booking-details-card pt-3 mb-5">
                    {!loading ? (
                        data &&
                        data.packages.length > 0 && (
                            <div
                                style={{
                                    borderBottom: '1px solid var(--light-gray)',
                                    padding: '0 2.25rem 0.65rem 2.25rem',
                                    marginBottom: '0.75rem',
                                }}
                            >
                                <Fragment>
                                    <h3 className="mb-2 ml-2">Package</h3>
                                    <Dropdown
                                        value={
                                            selectedPackage
                                                ? selectedPackage.name
                                                : 'None'
                                        }
                                        options={packageOptions.concat(
                                            data.packages.map((p) => {
                                                return {
                                                    label: p.name,
                                                    value: p.id,
                                                }
                                            })
                                        )}
                                        handleOptionSelect={(id) =>
                                            setSelectedPackage(
                                                data.packages.find(
                                                    (p) => p.id === id
                                                )
                                            )
                                        }
                                        placeholder="Package"
                                    />
                                </Fragment>
                            </div>
                        )
                    ) : (
                        <div
                            className="d-flex align-items-center justify-content-center"
                            style={{
                                borderBottom: '1px solid var(--light-gray)',
                                padding: '0.75rem 2.25rem 1.4rem 2.25rem',
                                marginBottom: '0.75rem',
                            }}
                        >
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    )}
                    <BookingSearch
                        dayOffset={
                            !selectedPackage || isSelectedPackageWithLodging
                                ? 0
                                : 1
                        }
                        minimumNights={isSelectedPackageWithLodging ? 1 : 0}
                        handleChange={handleSearchChange}
                        compact
                        withManyGuests
                    />
                </div>
                <MultipleBookingAccordion
                    selectedGuestHouses={selectedGuestHouses}
                    setSelectedGuestHouses={setSelectedGuestHouses}
                    selectedDormitoryRooms={selectedDormitoryRooms}
                    setSelectedDormitoryRooms={setSelectedDormitoryRooms}
                    selectedConferenceHalls={selectedConferenceHalls}
                    setSelectedConferenceHalls={setSelectedConferenceHalls}
                    seeAllGuestHouses={seeAllGuestHouses}
                    setSeeAllGuestHouses={setSeeAllGuestHouses}
                    seeAllDormitoryRooms={seeAllDormitoryRooms}
                    setSeeAllDormitoryRooms={setSeeAllDormitoryRooms}
                    isSelectedPackageWithGuestHouse={
                        isSelectedPackageWithGuestHouse
                    }
                    isSelectedPackageWithDormitoryRoom={
                        isSelectedPackageWithDormitoryRoom
                    }
                    isSelectedPackageWithLodging={isSelectedPackageWithLodging}
                    isSelectedPackageWithConferenceHall={
                        isSelectedPackageWithConferenceHall
                    }
                />
                {(isSelectedPackageWithLodging ||
                    selectedGuestHouses.length > 0 ||
                    selectedDormitoryRooms.length > 0) && (
                    <div className="mb-5">
                        <ChildrenCounter />
                    </div>
                )}
            </div>
            {(selectedGuestHouses.length > 0 ||
                selectedDormitoryRooms.length > 0 ||
                selectedConferenceHalls.length > 0) && (
                <div className="col-12 col-xl-6 p-0 pl-xl-5">
                    <ReservationSummary
                        selectedGuestHouses={selectedGuestHouses}
                        selectedDormitoryRooms={selectedDormitoryRooms}
                        selectedConferenceHalls={selectedConferenceHalls}
                    />
                </div>
            )}
        </div>
    )
}

export default PackageBooking
