import React, { useState, useEffect } from 'react'

const useDetectOutsideClick = (el, initialState) => {
    const [isActive, setIsActive] = useState(initialState)

    useEffect(() => {
        const pageClickEvent = (e) => {
            if (el.current !== null && !el.current.contains(e.target)) {
                setIsActive(!isActive)
            }
        }

        if (isActive) {
            window.addEventListener('click', pageClickEvent)
        }

        return () => {
            window.removeEventListener('click', pageClickEvent)
        }
    }, [isActive, el])

    return [isActive, setIsActive]
}

export default useDetectOutsideClick
