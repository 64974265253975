import React from 'react'

const Fees = () => (
    <div id="fees" className="help-center-section">
        <h2>Fees</h2>
        <h3>Service Fee</h3>
        <p>
            The Service Fee is calculated from the total value of all reserved
            facilities (guest houses, cottages, dormitories, conference halls,
            and grounds) in a booking.
        </p>
        <h3>Booking and Transaction Fees</h3>
        <p>
            Booking and Transaction Fees are convenience fees charged for
            booking through the Baguio Teachers Camp system.
        </p>
        <h3>Key Deposit</h3>
        <p>
            A deposit for lodging keys and remote control is collected for
            guests staying in lodgings upon check-in. This is fully refundable
            upon check-out.
        </p>
    </div>
)

export default Fees
