import React from 'react'

const SafetyProtocols = () => (
    <div id="safety" className="help-center-section">
        <h2>Safety Protocols</h2>
        <p>
            Wear a mask and practice social distancing when required by local
            laws and public health standards.
        </p>
        <p>All staff and guests must agree to:</p>
        <ul>
            <li>Wear a mask or face covering when interacting in person.</li>
            <li>Wear a face shield.</li>
            <li>
                Maintain 6 feet/2 meters of distance from each other at all
                times.
            </li>
        </ul>
    </div>
)

export default SafetyProtocols
