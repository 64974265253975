import React, { useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { BookingContext } from './App'
import checkmark from '../../assets/images/checkmark.jpg'

const BookingSuccess = ({ email, referenceNumber }) => {
    const { resetState } = useContext(BookingContext)

    useEffect(() => {
        resetState()
    }, [])

    return (
        <div
            className="d-flex flex-column align-items-center py-6 px-3 px-lg-8 mt-6"
            style={{ backgroundColor: '#F5F9FF' }}
        >
            <div className="d-flex align-items-center mb-3">
                <img
                    src={checkmark}
                    className="mr-3"
                    style={{ height: '24px' }}
                />
                <h2 className="mb-0">Booking Successful!</h2>
            </div>
            <div>
                <small>Reference No. {referenceNumber}</small>
            </div>
            <div className="py-5">
                <p className="mb-4 text-center">
                    A booking confirmation email and payment instructions will
                    be sent to your email{' '}
                    <span className="font-weight-bold">{email}</span>.
                </p>
                <p className="text-center">We'll see you soon!</p>
            </div>
            <Link
                className="btn btn-primary d-flex align-items-center justify-content-center px-4"
                to="/home"
            >
                <span className="fe fe-arrow-left mr-2"></span>Go back to
                homepage
            </Link>
        </div>
    )
}

export default BookingSuccess
