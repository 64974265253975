import { useContext } from 'react'
import { gql, useQuery } from '@apollo/client'
import { BookingContext } from './App'

const GET_ALL_AVAILABLE_UNITS = gql`
    query GetAllAvailableUnits(
        $checkIn: String!
        $checkOut: String!
        $activityCheckOut: String!
    ) {
        guestHouses: getAvailableUnits(
            type: "Guest House"
            checkIn: $checkIn
            checkOut: $checkOut
        ) {
            ...unitFields
        }
        dormitoryRooms: getAvailableUnits(
            type: "Dormitory Room"
            checkIn: $checkIn
            checkOut: $checkOut
        ) {
            ...unitFields
        }
        conferenceHalls: getAvailableUnits(
            type: "Conference Hall"
            checkIn: $checkIn
            checkOut: $activityCheckOut
        ) {
            ...unitFields
        }
    }

    fragment unitFields on Unit {
        id
        unitType
        name
        capacity
        numberOfRooms
        numberOfBathrooms
        capacityPrice
    }
`

const useAllAvailableUnits = () => {
    const { selectedPackage, checkIn, checkOut } = useContext(BookingContext)
    const { loading, data } = useQuery(GET_ALL_AVAILABLE_UNITS, {
        variables: {
            checkIn,
            checkOut,
            activityCheckOut:
                checkOut &&
                selectedPackage &&
                selectedPackage.packageInclusions.some(
                    (p) =>
                        p.name === 'Dormitory Room' || p.name === 'Guest House'
                )
                    ? checkOut.clone().subtract(1, 'days')
                    : checkOut,
        },
        fetchPolicy: 'network-only',
    })

    return {
        loading,
        data,
    }
}

export default useAllAvailableUnits
