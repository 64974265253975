import React from 'react'

const NumberCounter = ({
    number,
    handleAdd,
    handleSubtract,
    isAddDisabled,
}) => (
    <div className="d-flex align-items-center">
        <button
            className="btn outline-none pt-3"
            onClick={handleSubtract}
            disabled={number < 1}
        >
            <span
                className="fe fe-minus-circle"
                style={{
                    color: 'var(--primary)',
                    fontSize: '18px',
                }}
            ></span>
        </button>
        <span className="mt-1 mr-5 ml-5">{number}</span>
        <button
            className="btn outline-none pt-3"
            onClick={handleAdd}
            disabled={isAddDisabled}
        >
            <span
                className="fe fe-plus-circle"
                style={{
                    color: 'var(--primary)',
                    fontSize: '18px',
                }}
            ></span>
        </button>
    </div>
)

export default NumberCounter
