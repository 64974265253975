import React, { useRef } from 'react'
import useDetectOutsideClick from './useDetectOutsideClick'

const Dropdown = ({
    value,
    placeholder,
    options,
    handleOptionSelect,
    body,
    fontSize = '19px',
    disabled,
}) => {
    const dropdownRef = useRef(null)
    const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false)

    const menu =
        options !== undefined && options.length > 0 ? (
            <ul style={{ fontSize }}>
                {options.map((o) => (
                    <li key={o.value} onClick={() => handleMenuItemClick(o)}>
                        {o.label}
                    </li>
                ))}
            </ul>
        ) : (
            body
        )

    const handleMenuItemClick = (option) => {
        handleOptionSelect(option.value)
        setIsActive(false)
    }

    return (
        <div className="dropdown-container" ref={dropdownRef}>
            <button
                onClick={() => setIsActive(!isActive)}
                className="btn-link-transparent w-100"
                disabled={disabled}
            >
                <span
                    className="mr-3"
                    style={{
                        color: value ? 'var(--black)' : 'var(--gray)',
                        fontSize,
                    }}
                >
                    {value || placeholder}
                </span>
                <span
                    className="fe fe-chevron-down"
                    style={{ fontSize, color: "var(--primary)" }}
                ></span>
            </button>
            <div
                className={`dropdown-body ${isActive ? 'active' : 'inactive'}`}
            >
                {menu}
            </div>
        </div>
    )
}

export default Dropdown
