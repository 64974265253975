import React, { useContext } from 'react'
import { BookingContext } from './App'
import Dropdown from './Dropdown'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import 'react-phone-number-input/style.css'
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input'
import { positiveNumber, isValidEmail } from './utils'

const GuestDetailsForm = ({
    category,
    setCategory,
    guestType,
    setGuestType,
    guestName,
    setGuestName,
    contactPerson,
    setContactPerson,
    contactNumber,
    setContactNumber,
    email,
    setEmail,
    address,
    setIsPwd,
    pwds,
    setPwds,
    setAddress,
    activityPurpose,
    setActivityPurpose,
    loading,
    isValidated,
}) => {
    const { unitType, adults } = useContext(BookingContext)

    return (
        <div className="booking-details-card p-4">
            <Form.Row>
                <Form.Group as={Col} lg="6" className="pr-lg-3">
                    <Form.Label>Guest Type</Form.Label>
                    <div
                        className={`d-flex align-items-center form-control ${
                            isValidated && !guestType ? 'is-invalid' : ''
                        } ${loading ? 'disabled' : ''}`}
                    >
                        <Dropdown
                            value={guestType}
                            options={[
                                {
                                    label: 'Individual',
                                    value: 'Individual',
                                },
                                {
                                    label: 'Organization',
                                    value: 'Organization',
                                },
                            ]}
                            handleOptionSelect={setGuestType}
                            placeholder="Choose one"
                            fontSize="0.9375rem"
                            disabled={loading}
                        />
                    </div>
                    <Form.Control.Feedback type="invalid">
                        Please choose a guest type.
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} lg="6" className="pl-lg-3">
                    <Form.Label>Category</Form.Label>
                    <div
                        className={`d-flex align-items-center form-control ${
                            isValidated && !category ? 'is-invalid' : ''
                        } ${loading ? 'disabled' : ''}`}
                    >
                        <Dropdown
                            value={category}
                            options={[
                                {
                                    label: 'Non-government',
                                    value: 'Non-government',
                                },
                                { label: 'Government', value: 'Government' },
                            ]}
                            handleOptionSelect={setCategory}
                            placeholder="Choose one"
                            fontSize="0.9375rem"
                            disabled={loading}
                        />
                    </div>
                    <Form.Control.Feedback type="invalid">
                        Please choose a category.
                    </Form.Control.Feedback>
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} lg="6" className="pr-lg-3">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                        required
                        type="text"
                        placeholder={
                            guestType
                                ? guestType === 'Individual'
                                    ? 'Enter your full name'
                                    : 'Enter the name of your organization'
                                : 'Please choose a Guest Type'
                        }
                        defaultValue={guestName}
                        onChange={(e) => setGuestName(e.target.value)}
                        isInvalid={isValidated && !guestName}
                        disabled={loading || !guestType}
                    />
                    <Form.Control.Feedback type="invalid">
                        Please provide a name.
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} lg="6" className="pl-lg-3">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                        required
                        type="text"
                        placeholder="Enter Email"
                        defaultValue={email}
                        onChange={(e) => setEmail(e.target.value)}
                        isInvalid={isValidated && !isValidEmail(email)}
                        disabled={loading}
                    />
                    <Form.Control.Feedback type="invalid">
                        Please provide a valid email.
                    </Form.Control.Feedback>
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} lg="6" className="pr-lg-3">
                    <Form.Label>Contact Person</Form.Label>
                    <Form.Control
                        required
                        type="text"
                        placeholder="Enter contact person's full name"
                        defaultValue={contactPerson}
                        onChange={(e) => setContactPerson(e.target.value)}
                        isInvalid={isValidated && !contactPerson}
                        disabled={loading}
                    />
                    <Form.Control.Feedback type="invalid">
                        Please provide contact person.
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} lg="6" className="pl-lg-3">
                    <Form.Label>Contact Number</Form.Label>
                    <PhoneInput
                        international
                        countryCallingCodeEditable={false}
                        placeholder="Enter contact number"
                        defaultCountry="PH"
                        value={contactNumber}
                        onChange={setContactNumber}
                        disabled={loading}
                        className={`form-control form-control-focus-within
                            ${
                                isValidated &&
                                (!contactNumber ||
                                    !isPossiblePhoneNumber(
                                        contactNumber.toString()
                                    ))
                                    ? ' is-invalid'
                                    : ''
                            }
                                `}
                    />
                    <Form.Control.Feedback type="invalid">
                        Please provide valid contact number.
                    </Form.Control.Feedback>
                </Form.Group>
            </Form.Row>
            <Form.Group>
                <Form.Label>Address</Form.Label>
                <Form.Control
                    required
                    type="text"
                    placeholder="Enter street address"
                    defaultValue={address}
                    onChange={(e) => setAddress(e.target.value)}
                    isInvalid={isValidated && !address}
                    disabled={loading}
                />
                <Form.Control.Feedback type="invalid">
                    Please provide address.
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
                <Form.Label>Activity Purpose</Form.Label>
                <Form.Control
                    required
                    as="textarea"
                    col={2}
                    placeholder="Enter purpose of activity"
                    defaultValue={activityPurpose}
                    onChange={(e) => setActivityPurpose(e.target.value)}
                    isInvalid={isValidated && !activityPurpose}
                    disabled={loading}
                />
                <Form.Control.Feedback type="invalid">
                    Please provide activity purpose.
                </Form.Control.Feedback>
            </Form.Group>
            {unitType !== 'Conference Hall' && (
                <div className="booking-details-card p-3">
                    <div className="d-flex flex-wrap">
                        <Form.Group as={Col} lg="6" className="pr-lg-3">
                            <Form.Label>
                                How many senior citizens and/or PWDs are in your
                                group?
                            </Form.Label>
                            <Form.Control
                                required
                                type="number"
                                placeholder="Enter number of senior citezens and/or PWDs"
                                defaultValue={pwds}
                                onKeyDown={(e) => positiveNumber(e)}
                                onChange={(e) => setPwds(e.target.value)}
                                onWheel={(e) => e.target.blur()}
                                max={adults}
                                min={0}
                                isInvalid={isValidated && pwds > adults}
                                disabled={loading}
                            />
                            <Form.Control.Feedback type="invalid">
                                Senior citizens/PWDs cannot be more than the
                                number of adult guests.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                            as={Col}
                            lg="6"
                            className="d-flex flex-column pl-lg-3"
                        >
                            <Form.Label>
                                Are you one of the senior citizens/PWDs?
                            </Form.Label>
                            <div className="d-flex flex-1">
                                <Form.Check
                                    inline
                                    label="Yes"
                                    name="group1"
                                    type="radio"
                                    id="inline-radio-1"
                                    onClick={() => setIsPwd(true)}
                                    disabled={pwds <= 0}
                                />
                                <Form.Check
                                    inline
                                    label="No"
                                    name="group1"
                                    type="radio"
                                    id="inline-radio-2"
                                    onClick={() => setIsPwd(false)}
                                    disabled={pwds <= 0}
                                />
                            </div>
                        </Form.Group>
                    </div>
                </div>
            )}
        </div>
    )
}

export default GuestDetailsForm
