import React, { Fragment, useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Image from 'react-bootstrap/Image'
import Card from 'react-bootstrap/Card'
import { BookingContext } from './App'
import welcomeImg from '../../assets/images/welcome-to-btc-img.jpg'
import conferenceHallImg from '../../assets/images/conference-hall-interior.jpg'
import groundImg from '../../assets/images/grounds.jpg'
import guestHouseImg from '../../assets/images/cottages-and-guesthouses.jpg'
import dormitoryImg from '../../assets/images/btc-0021.jpg'
import museumImg from '../../assets/images/btc-museum.jpg'
import landscapeImg from '../../assets/images/landscape-architecture.jpg'
import UnitsSearch from './UnitsSearch'

const Home = () => {
    const { setUnitType, resetState } = useContext(BookingContext)

    useEffect(() => {
        resetState()
    }, [])

    return (
        <Fragment>
            <div
                className="d-flex flex-column justify-content-center home-background vw100-background px-3 px-md-7-5"
                style={{
                    height: '90vh',
                }}
            >
                <h1 style={{ fontSize: '38px' }}>
                    Book your experience with us
                </h1>
                <p>
                    Education-related activities during summer season in DepED
                    Baguio Teachers' Camp
                </p>
                <UnitsSearch />
            </div>
            <div className="flex-1 py-6">
                <div className="header-overline-primary"></div>
                <div className="d-flex flex-wrap">
                    <div className="col-12 col-lg-6 p-0 pb-3 pr-lg-6">
                        <h1>Welcome to DepEd Teachers Camp</h1>
                        <p>
                            For the past 100 years, Baguio Teachers Camp (BTC)
                            served as a training center and venue for teachers
                            from all over the Philippines who come during the
                            summer break to attend special courses in education.
                            Despite the rapid modernization of the country’s
                            summer capital, BTC manages to preserve the Baguio
                            that we all know and love. From originally serving
                            the needs of Filipino and American educators, BTC
                            has in the last 100 years expanded and has opened
                            its doors to the general public. People from
                            different walks of life now enjoy everything that
                            the Camp has to offer.
                        </p>
                    </div>
                    <Image
                        src={welcomeImg}
                        className="col-12 col-lg-6 px-0"
                        rounded
                    />
                </div>
            </div>
            <div
                className="flex-1 vw100-background py-6 px-3 px-md-7-5 mt-4"
                style={{
                    color: '#FFFFFF',
                    backgroundColor: 'var(--primary)',
                }}
            >
                <div className="header-overline-white"></div>
                <h1>Activities</h1>
                <p>
                    Conference halls are named after outstanding political
                    leaders and educators - Benitez, Romulo, Quezon, Quirino,
                    Oring-ao, Pages, Abada and Albert have a total capacity of
                    about 3,000 guests.
                </p>
                <div className="d-flex flex-wrap">
                    <div className="col-12 col-lg-6 px-0 pb-3 pr-lg-2">
                        <Card className="h-100 border-0">
                            <Card.Img
                                variant="top"
                                src={conferenceHallImg}
                                style={{ height: '276px' }}
                            />
                            <Card.Body
                                className="d-flex flex-column"
                                style={{ color: 'var(--black)' }}
                            >
                                <Card.Title as="h3">
                                    Conference Halls
                                </Card.Title>
                                <Card.Text>
                                    These may be used for seminars, trainings,
                                    gatherings and celebrations.
                                </Card.Text>
                                <Link
                                    className="btn btn-outline-primary mt-auto px-4"
                                    style={{ width: 'fit-content' }}
                                    to="/conferenceHallBooking"
                                    onClick={() => resetState()}
                                >
                                    Book now
                                </Link>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="col-12 col-lg-6 px-0 pb-3 pl-lg-2">
                        <Card className="h-100 border-0">
                            <Card.Img
                                variant="top"
                                src={groundImg}
                                style={{ height: '276px' }}
                            />
                            <Card.Body
                                className="d-flex flex-column"
                                style={{ color: 'var(--black)' }}
                            >
                                <Card.Title as="h3">Grounds</Card.Title>
                                <Card.Text>
                                    These may be used for trainings, gatherings
                                    and celebrations.
                                </Card.Text>
                                <Link
                                    className="btn btn-outline-primary mt-auto px-4"
                                    style={{ width: 'fit-content' }}
                                    to="/groundBooking"
                                    onClick={() => resetState()}
                                >
                                    Book now
                                </Link>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>
            <div className="flex-1 pt-6">
                <div className="header-overline-primary"></div>
                <h1>Stay</h1>
                <p>
                    Beyond its role in the long journey of Philippine education,
                    the Baguio Teachers Camp remains to offer that old familiar
                    ambiance of Baguio City that travelers and guests hauntingly
                    long for. A comfortable place of rest, of study, and
                    leisure, for individuals, circle of friends, and families,
                    while keeping in touch with Baguio’s glory and antiquity.
                </p>
                <div className="d-flex flex-wrap">
                    <div className="col-12 col-lg-6 px-0 pb-3 pr-lg-2">
                        <Card
                            className="h-100"
                            style={{
                                border: '1px solid var(--primary)',
                            }}
                        >
                            <Card.Img
                                variant="top"
                                src={guestHouseImg}
                                style={{ height: '276px' }}
                            />
                            <Card.Body className="d-flex flex-column">
                                <Card.Title as="h3">
                                    Cottages and Guesthouses
                                </Card.Title>
                                <Card.Text>
                                    Aside from its aim to inspire the teachers
                                    with innovative ideas that could well
                                    benefit the education profession, BTC also
                                    serves as a venue to rejuvenate the tired
                                    bodies of our teachers, thus affordable
                                    cottages are offered. In total, there are 27
                                    cottages with a capacity of 226 occupants,
                                    and 22 guesthouses that can accommodate 209
                                    personnel, depending on the size and number
                                    of bedrooms.
                                </Card.Text>
                                <Link
                                    className="btn btn-outline-primary mt-auto px-4"
                                    style={{ width: 'fit-content' }}
                                    to="/lodgingBooking"
                                    onClick={() => {
                                        resetState()
                                        setUnitType('Guest House')
                                    }}
                                >
                                    Book now
                                </Link>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="col-12 col-lg-6 px-0 pb-3 pl-lg-2">
                        <Card
                            className="h-100"
                            style={{
                                border: '1px solid var(--primary)',
                            }}
                        >
                            <Card.Img
                                variant="top"
                                src={dormitoryImg}
                                style={{ height: '276px' }}
                            />
                            <Card.Body className="d-flex flex-column">
                                <Card.Title as="h3">Dormitories</Card.Title>
                                <Card.Text>
                                    Dormitories are restored and ready to use.
                                    There are 11 dormitories with a total
                                    capacity of 1,058 tenants. Offered for a
                                    reasonable price, dormitories are big as
                                    they were made for teachers, long before the
                                    tourist boom. When teachers are not using
                                    them, dormitories are rented out to
                                    tourists.
                                </Card.Text>
                                <Link
                                    className="btn btn-outline-primary mt-auto px-4"
                                    style={{ width: 'fit-content' }}
                                    to="/lodgingBooking"
                                    onClick={() => {
                                        resetState()
                                        setUnitType('Dormitory Room')
                                    }}
                                >
                                    Book now
                                </Link>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>
            <div className="flex-1 pt-6">
                <div className="header-overline-primary"></div>
                <h1>Explore</h1>
                <p>
                    BTC continues to showcase Baguio’s proud history and unique
                    beauty. Its facilities have been carefully restored to
                    harmonize with the city’s natural landscape and culture.
                    These structures perfectly complement the abundance and rich
                    collection of mountain flora – sunflowers, cosmos,
                    magnolias, and many more that can be seen all around the
                    Camp.
                </p>
                <div className="d-flex flex-wrap">
                    <div className="col-12 col-lg-6 px-0 pb-3 pr-lg-2">
                        <Card
                            className="h-100"
                            style={{
                                border: '1px solid var(--primary)',
                            }}
                        >
                            <Card.Img
                                variant="top"
                                src={museumImg}
                                style={{ height: '276px' }}
                            />
                            <Card.Body>
                                <Card.Title as="h3">Camp Museum</Card.Title>
                                <Card.Text>
                                    BTC features a museum that holds a
                                    collection of significant photos from the
                                    Camp’s humble beginnings. It also exhibits
                                    historical items that commemorate BTC’s
                                    contribution to Filipino education. These
                                    provide a quick glance at Baguio City’s
                                    untouched nature.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="col-12 col-lg-6 px-0 pb-3 pl-lg-2">
                        <Card
                            className="h-100"
                            style={{
                                border: '1px solid var(--primary)',
                            }}
                        >
                            <Card.Img
                                variant="top"
                                src={landscapeImg}
                                style={{ height: '276px' }}
                            />
                            <Card.Body>
                                <Card.Title as="h3">
                                    Landscape Architecture
                                </Card.Title>
                                <Card.Text>
                                    BTC features a number of picturesque
                                    structures that showcase Baguio’s unique
                                    flora. The existing wooden bridge and picnic
                                    tables are surrounded by beautiful
                                    sunflowers and pine trees.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Home
