import React, { useContext } from 'react'
import { HashLink } from 'react-router-hash-link'
import useWindowSize from './useWindowSize'
import { BookingContext } from './App'
import BookingSearch from './BookingSearch'
import UnitTypeDropdown from './UnitTypeDropdown'

const UnitsSearch = () => {
    const { checkIn, checkOut, adults, unitType } = useContext(BookingContext)
    const valid = unitType && checkIn && checkOut && adults > 0
    const [screenWidth] = useWindowSize()
    const compact = screenWidth < 1200

    return (
        <div
            className="align-items-center"
            style={
                compact
                    ? {
                          backgroundColor: '#FFFFFF',
                          color: 'var(--black)',
                          borderRadius: '10px',
                          padding: '1.5rem 0',
                      }
                    : {
                          display: 'flex',
                          backgroundColor: '#FFFFFF',
                          color: 'var(--black)',
                          borderRadius: '40px',
                          padding: '0.75rem 2.25rem 0.375rem 2.25rem',
                      }
            }
        >
            <UnitTypeDropdown compact={compact} />
            <div
                className={`${
                    compact ? 'd-none' : ''
                } vertical-divider mt-2 mr-1 mb-3`}
            ></div>
            <BookingSearch
                compact={compact}
                minimumNights={
                    unitType === 'Guest House' || unitType === 'Dormitory Room'
                        ? 1
                        : 0
                }
                dayOffset={
                    unitType === 'Conference Hall' || unitType === 'Ground'
                        ? 1
                        : 0
                }
                withManyGuests={
                    unitType === 'Conference Hall' || unitType === 'Ground'
                }
            />
            <div className="d-flex justify-content-center">
                {valid ? (
                    <HashLink
                        to={
                            unitType === 'Conference Hall'
                                ? '/conferenceHallBooking#search'
                                : unitType === 'Ground'
                                ? '/groundBooking#search'
                                : '/lodgingBooking#search'
                        }
                        className="btn btn-primary outline-none px-4"
                        style={{
                            height: '40px',
                            width: compact ? '40%' : '100px',
                        }}
                    >
                        Search
                    </HashLink>
                ) : (
                    <button
                        className="btn btn-primary disabled px-4"
                        style={{
                            height: '40px',
                            width: compact ? '40%' : '100px',
                        }}
                        disabled
                    >
                        Search
                    </button>
                )}
            </div>
        </div>
    )
}

export default UnitsSearch
