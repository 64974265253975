import React, { Fragment, useState } from 'react'
import LodgingUnitCounter from './LodgingUnitCounter'
import LodgingUnitSearchCounter from './LodgingUnitSearchCounter'

const LodgingUnitTabs = ({
    availableUnits,
    selectedUnits,
    setSelectedUnits,
    seeAll,
    setSeeAll,
    disabled,
}) => {
    const [isSearch, setIsSearch] = useState(false)

    const tabChangeHandler = (search) => {
        setSelectedUnits([])
        setSeeAll(false)
        setIsSearch(search)
    }

    return (
        <div>
            <div className="d-flex mb-4">
                <button
                    className="btn outline-none text-nowrap rounded-0"
                    style={{
                        borderBottom: `1px solid var(--${
                            isSearch ? 'light-gray' : 'primary'
                        })`,
                    }}
                    onClick={() => tabChangeHandler(false)}
                >
                    <h3
                        style={{
                            color: isSearch ? 'var(--gray)' : 'var(--black)',
                        }}
                    >
                        Available Units
                    </h3>
                </button>
                <button
                    className="btn outline-none text-nowrap rounded-0"
                    style={{
                        borderBottom: `1px solid var(--${
                            isSearch ? 'primary' : 'light-gray'
                        })`,
                    }}
                    onClick={() => tabChangeHandler(true)}
                >
                    <h3
                        style={{
                            color: isSearch ? 'var(--black)' : 'var(--gray)',
                        }}
                    >
                        Advanced Search
                    </h3>
                </button>
                <div
                    className="w-100"
                    style={{ borderBottom: '1px solid var(--light-gray)' }}
                ></div>
            </div>
            {!disabled && (
                <Fragment>
                    {isSearch ? (
                        <LodgingUnitSearchCounter
                            availableUnits={availableUnits}
                            selectedUnits={selectedUnits}
                            setSelectedUnits={setSelectedUnits}
                            seeAll={seeAll}
                            setSeeAll={setSeeAll}
                            setIsSearch={setIsSearch}
                        />
                    ) : (
                        <Fragment>
                            <p style={{ color: 'var(--gray)' }}>
                                Please select units you want to reserve. Per
                                unit details may vary depending where the units
                                are located inside the camp.
                            </p>
                            <p
                                className="mb-4"
                                style={{ color: 'var(--gray)' }}
                            >
                                If you are looking for a specific unit, please
                                use our{' '}
                                <a
                                    href="#/"
                                    className="text-decoration-underline"
                                    onClick={() => setIsSearch(true)}
                                    style={{ color: 'var(--primary)' }}
                                >
                                    advance search
                                </a>{' '}
                                instead.
                            </p>
                            <LodgingUnitCounter
                                availableUnits={availableUnits}
                                selectedUnits={selectedUnits}
                                setSelectedUnits={setSelectedUnits}
                                seeAll={seeAll}
                                setSeeAll={setSeeAll}
                            />
                        </Fragment>
                    )}
                </Fragment>
            )}
        </div>
    )
}

export default LodgingUnitTabs
