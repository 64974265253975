import React, { Fragment } from 'react'
import Nav from 'react-bootstrap/Nav'
import SafetyProtocols from './helpCenterSections/SafetyProtocols'
import Reservations from './helpCenterSections/Reservations'
import Confirmation from './helpCenterSections/Confirmation'
import Discounts from './helpCenterSections/Discounts'
import Fees from './helpCenterSections/Fees'
import RefundsAndCancellations from './helpCenterSections/RefundsAndCancellations'
import CheckInAndCheckOut from './helpCenterSections/CheckInAndCheckOut'
import UsageOfFacilities from './helpCenterSections/UsageOfFacilities'
import lightbulb from '../../assets/images/light-bulb.png'

const HelpCenter = () => (
    <Fragment>
        <div
            className="d-flex align-items-center vw100-background px-3 px-md-7-5 mb-5"
            style={{ height: '120px', backgroundColor: '#EDF3F0' }}
        >
            <h1 className="mb-0">Help Center</h1>
        </div>
        <div
            className="d-flex align-items-center p-4 mb-5"
            style={{
                backgroundColor: '#FCF9E4',
                border: '1px solid #F3AA00',
                borderRadius: '10px',
            }}
        >
            <img src={lightbulb} className="mr-4" />
            <p className="mb-0">
                <span>
                    The terms and conditions of this facility booking agreement
                    are designed to be fair to both parties. They protect the
                    facility owner while allowing for reasonable use of the
                    facility by the client during the reserved period. For
                    queries and further information, contact us at telephone
                    numbers (074) 442-3517 and 4427149, or send us an email at{' '}
                </span>
                <a href="mailto:clientrelations_deped100@yahoo.com">
                    clientrelations_deped100@yahoo.com
                </a>
            </p>
        </div>
        <div className="d-flex">
            <div
                className="d-none d-md-block"
                style={{
                    WebkitBoxFlex: 0,
                    flex: '0 0 250px',
                    maxWidth: '250px',
                }}
            >
                <div className="lighter-blue-card sticky-box py-4 px-3">
                    <h4 style={{ padding: '0.5rem 1rem' }}>Quick Links</h4>
                    <Nav className="flex-column">
                        <Nav.Link href="/help#safety">
                            Safety Protocols
                        </Nav.Link>
                        <Nav.Link href="/help#reservations">
                            Reservations
                        </Nav.Link>
                        <Nav.Link href="/help#confirmation">
                            Confirmation
                        </Nav.Link>
                        <Nav.Link href="/help#discounts">Discounts</Nav.Link>
                        <Nav.Link href="/help#fees">Fees</Nav.Link>
                        <Nav.Link href="/help#refunds">
                            Refunds and Cancellations
                        </Nav.Link>
                        <Nav.Link href="/help#checkin">
                            Check-in and Check-out
                        </Nav.Link>
                        <Nav.Link href="/help#facilities">
                            Usage of Facilities
                        </Nav.Link>
                    </Nav>
                </div>
            </div>
            <div className="pt-3 px-0 pl-md-5">
                <SafetyProtocols />
                <Reservations />
                <Confirmation />
                <Discounts />
                <Fees />
                <RefundsAndCancellations />
                <CheckInAndCheckOut />
                <UsageOfFacilities />
            </div>
        </div>
    </Fragment>
)

export default HelpCenter
