import React, { Fragment } from 'react'

const Contact = () => (
    <Fragment>
        <div className="header-overline-primary mt-6"></div>
        <h1>Contact Us</h1>
        <div className="d-flex mb-3">
            <span
                className="fe fe-map-pin mr-3"
                style={{ color: 'var(--primary)' }}
            ></span>
            <div className="pt-1">
                <h3>Address</h3>
                <p className="mb-0">Baguio Teachers Camp,</p>
                <p>Leonard Wood Road, Baguio City</p>
            </div>
        </div>
        <div className="d-flex mb-3">
            <span
                className="fe fe-clock mr-3"
                style={{ color: 'var(--primary)' }}
            ></span>
            <div className="pt-1">
                <h3>Office Hours</h3>
                <p className="mb-0">Monday-Sunday</p>
                <p>8:00 AM – 5:00 PM</p>
            </div>
        </div>
        <div className="d-flex">
            <span
                className="fe fe-mail mr-3"
                style={{ color: 'var(--primary)' }}
            ></span>
            <div className="pt-1">
                <h3>For Inquiries</h3>
                <p className="mb-0">(074) 442-3517 and 442-7149</p>
                <a href="mailto:clientrelations_deped100@yahoo.com">
                    clientrelations_deped100@yahoo.com
                </a>
            </div>
        </div>
    </Fragment>
)

export default Contact
