import React, { useContext } from 'react'
import { BookingContext } from './App'
import NumberCounter from './NumberCounter'

const ChildrenCounter = () => {
    const { children, setChildren } = useContext(BookingContext)

    return (
        <div className="booking-details-card d-flex align-items-center p-4">
            <div className="flex-2">
                Children (ages 0-7) are free but do not occupy beds on their
                own.
            </div>
            <div className="d-flex flex-column flex-1 align-items-center ml-3">
                <h3 className="text-center">Number of Children</h3>
                <NumberCounter
                    number={children}
                    handleAdd={() => setChildren(children + 1)}
                    handleSubtract={() => setChildren(children - 1)}
                />
            </div>
        </div>
    )
}

export default ChildrenCounter
